<template>
<div class="history-content">
	<div class="van-nav-bar van-hairline--bottom routes">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback" @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
			</div>
			<div class="van-nav-bar__title van-ellipsis">
				{{ $t('mine.gamerecord') }}
			</div>
			<div class="van-nav-bar__right van-haptics-feedback"  @click="showRight ? showRight = false : showRight = true">
				<i class="van-badge__wrapper van-icon van-icon-apps-o"
				style="font-size: 24px;"></i>
			</div>
		</div>
	</div>
	<van-tabs color="#7969aa" title-active-color="" :before-change="beforeChange" title-inactive-color="#7969aa" animated>
	  <van-tab :title="v.name" class="det" style="margin: 0.5rem auto 0;" v-for="(v,key) in navList" :key="key">
		  <div class="history">
			  <van-pull-refresh v-model="isLoading" class="fixedStyle"  @refresh="onRefresh">
			    <van-list
			        v-model="loading"
			        :finished="finished"
			        :immediate-check="false"
			        :finished-text="$t('common.nomore')"
			        >
			  	  <van-cell-group inset>
			  	    <van-cell v-for="(item,i) in list" :key="i"  @click="toDetail(item)" >
			  			<template #title>
			  				<p  style="background: rgb(54, 201, 137);">
			  					$
			  				</p>
			  				<div >
			  					<span class="custom-title" >
			  						{{item.name}}
			  					</span>
			  					<span >
									{{ $t('more.fy6') }}:<span v-for="(datai,ss) in item.data" :key="ss" >{{$t('game.'+datai.type)}} </span>
			  					</span>
			  				</div>
			  			</template>
						<template #default>
							<span  style="color: rgb(178, 168, 205); margin-left: 1rem; font-size: 13px;">
								{{ $t('more.fy7', { count: item.expect }) }}
							</span>
							<span  style="font-size: 16px; color: rgb(54, 201, 137);">
								{{item.money}}
							</span>
						</template>
			  		</van-cell>
			  	  </van-cell-group>
			    </van-list>
			    <van-empty :description="$t('common.nodata')" v-if="list.length === 0"/>
			  </van-pull-refresh>
		  </div>
	  </van-tab>
	  
	</van-tabs>
	<van-popup v-model="showRight" position="right" class="his" :style="{ width: '80%' ,height: '100%'}" >
		
			<p>
				{{ $t('more.fy8') }}
			</p>
			<ul>
				<li @click="changeLottery(v.id)" v-for="(v,key) in game_type_list" :key="key" :class="v.id==lottery_id?'active':''">
					{{v.name}}
				</li>
				
			</ul>
			<div class="btn">
				<p @click="lottery_id='all'">
					{{ $t('more.fy9') }}
				</p>
				<p @click="showRight=false">
					{{ $t('more.fy10') }}
				</p>
			</div>
		
	</van-popup>
</div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
		showRight:false,
		lottery_id:'all',
		game_type_list: [{},{},{},{}],
		status:0,
		page:1,
		isLoading: false,
		count:0,
		limit:10,
		loading: false,
		finished: false,
		refreshing: false,
		tableList:[],
		list:[],
		navList:[{name:this.$t('more.fy11'),value:0},{name:this.$t('more.fy12'),value:1},{name:this.$t('more.fy13'),value:2},{name:this.$t('more.fy14'),value:3}],
    };
  },
  mounted () {
    this.getUserGameList()
    window.addEventListener("scroll", this.scrollTops, true);
  },
  
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollTops);
  },
  methods: {
    back(){
      this.$router.push({path:'Mine'})
    },
	getGameItem(){
	  this.$http({
	    method: 'get',
	    url: 'lottery_hot'
	  }).then(res=>{
	    this.game_type_list = res.data
	  })
	},
	toDetail(item){
	  this.$router.push({path:'/GameDetail?expect='+item.expect+"&lid="+item.data[0].lottery.id+"&name="+item.name+"&money="+item.money+"&touzhu="+item.touzhu+"&time="+item.create_time+"&status="+item.status})  
	},
	beforeChange(index) {
	  // 返回 false 表示阻止此次切换
	  this.status = index;
	  this.getUserGameList()
	  return true;
	},
	longPress() {
	  document.querySelector('.fixedStyle').scrollTop = 0
	},
	scrollTops(){
	  this.scrollTop1 = document.querySelector('.fixedStyle').scrollTop
	},
    onRefresh(){
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t('common.refresh'));
      }, 500);
    },
	changeLottery(id){
		this.lottery_id = id;
		this.getUserGameList();
	},
    getUserGameList(){
      this.$http({
        method: 'get',
		data:{page: this.page,limit: this.limit,status:this.status,lottery_id:this.lottery_id},
        url: 'user_get_game_list'
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data)
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
	onLoad() {
	  //初始化列表
	  this.getUserGameList()
	  let timer = setTimeout(() => {
	    if (this.refreshing) {
	      this.tableList = [];
	      this.refreshing = false;
	    }
	    this.loading = false;
	    if (this.tableList.length === this.count) {
	      this.finished = true;
	    }
	    this.finished && clearTimeout(timer);//清除计时器
	  }, 500);
	},

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameList();
	  this.getGameItem();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.custom-title {
    font-size: 1rem
}

.history .van-cell__value {
    flex: auto
}

.history .van-cell__value span {
    display: block
}



.history .van-cell__title {
    flex: auto;
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.history .van-cell__title>p {
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    color: #fff;
    margin-right: 0.5rem
}

.history .van-cell__title>div {
    display: flex;
    flex-direction: column;
    flex: 1
}

.history .van-cell__label {
    font-size: 1rem;
    color: #000
}

:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.van-hairline--bottom:after {
    border-width: 0
}

.history-content {
    width: 100%;
    height: 100%;
    background: #f0f0f0
}

.history-content .van-tabs__line {
    border-radius: 20px
}

.history-content .his {
    width: 80%;
    height: 100%;
    padding: 5%
}

.history-content .his p {
    text-align: left;
    font-size: 1rem
}

.history-content .his ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1.66667rem
}

.history-content .his ul li {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #d04194;
    border: 1px solid #d04194;
    border-radius: .33333rem;
    margin-right: .66667rem;
    margin-bottom: .66667rem;
    padding: .46667rem 0
}

.history-content .his ul li.active {
    color: #fff;
    border: none;
    background: linear-gradient(20deg,#a253d0,#d63f8c)
}

.history-content .his .btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5%
}

.history-content .his .btn p {
    color: #a253d0;
    border: 1px solid #a253d0;
    border-radius: 3.33333rem;
    padding: .46667rem 2.66667rem;
    font-size: .93333rem
}

.history-content .his .btn p:last-child {
    color: #fff;
    border: none;
    background: #a253d0
}

.his p {
    color: #4c368c;
    font-weight: 600
}

</style>
