<template>
  <van-popover v-model="showPopover" trigger="click" @select="handle" :actions="list" style="cursor: pointer;">
    <template #reference>
    	<van-button class="van-button van-button--primary van-button--normal van-button--plain" type="button" style="color: rgb(255, 255, 255); border-color: rgb(255, 255, 255); font-size: 14px; height: 26px; padding: 0px 10px; border-radius: 4px; background: none;">{{ $t('Language') }}</van-button>
    </template>
  </van-popover>
</template>

<script>
export default {
  name: 'change-language',
  data() {
    return {
		showPopover: false,
      list: [
			{ key: 'kr-kr', text: '한국어' }, // 中文
			{ key: 'en', text: 'English' }, // 英语
			{ key: 'jp-jp', text: '日本語' }, // 中文
			{ key: 'zh-CN', text: '中文' }, // 中文
        
      ],
    }
  },
  methods: {
    handle(value) {
      this.$i18n.locale = value.key
      localStorage.setItem('change-language', value.key)
      location.reload()
    },
  },
}
</script>
<style scoped lang="less">
.van-popover__action {
	width: 2rem;
	height: 5.867vw;
	padding: 0 2.133vw;
	font-size: 2.867vw;
	line-height: 2.667vw;
	cursor: pointer;
}
</style>
