<template>
<div class="bind" id="bingCradForm">
	<div class="van-nav-bar van-hairline--bottom">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback"  @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow" ></i>
			</div>
			<div class="van-nav-bar__title van-ellipsis">
				{{$t('bank.bindCardTitle')}}
			</div>
			</div>
	</div>
	<van-cell-group>
		<!-- <van-cell   >
			<template #default>
			    <van-dropdown-menu >
			      <van-dropdown-item style="font-size: 2rem;" v-model="bank" :options="banks" />
			    </van-dropdown-menu>
				<p>
					{{$t('bank.selectBankCard')}}
				</p>
			</template>
		</van-cell> -->
		<van-field @touchstart="handleTouchStart" @touchend="handleTouchEnd" @paste.prevent @copy.prevent @contextmenu.prevent @dblclick.prevent  v-model="bank" :label="$t('bank.bankCard')" :placeholder="$t('bank.bankCard')" />
		<van-field @touchstart="handleTouchStart" @touchend="handleTouchEnd" @paste.prevent @copy.prevent @contextmenu.prevent @dblclick.prevent  v-model="bankid" :label="$t('bank.accountNumber')" type="digit" :placeholder="$t('bank.enterAccountNumber')" />
		<van-field @touchstart="handleTouchStart" @touchend="handleTouchEnd" @paste.prevent @copy.prevent @contextmenu.prevent @dblclick.prevent  v-model="bankname" :label="$t('bank.accountHolder')" type="text" :placeholder="$t('bank.enterRealName')" />
		<van-field @touchstart="handleTouchStart" @touchend="handleTouchEnd" @paste.prevent @copy.prevent @contextmenu.prevent @dblclick.prevent  v-model="password" :label="$t('bank.withdrawalPassword')" type="password" :placeholder="$t('bank.enterWithdrawalPassword')" />

	</van-cell-group>
	<div class="btn">
		<a @click="bindCard()">
			{{$t('bank.addBankCard')}}
		</a>
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      bankname:'',
      password:'',
        bank:"",
      value1: 0,
      option1: [
      { text: this.$t('bank.bankCard'), value: 0 }
      ],
      touchTimer:null,
    };
  },
  methods: {
    handlePaste(event) {
      event.preventDefault();  // 阻止粘贴
    },
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.bankid === "" || this.bankid === null || this.bankid === undefined){
        this.$toast(this.$t('bank.enterBankCardNumber'));
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail(this.$t('bank.selectBank'));
        return false;
      }
      this.$http({
        method: 'post',
        data:{bankid:this.bankid,bank:this.bank,bankname:this.bankname,password:this.password},
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.$store.commit('setUserInfoValue', res.data);
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
		  this.bank = res.data[0].value;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    handleTouchStart(e){
      console.log('触摸');
      this.touchTimer = setTimeout(function () {
        console.log('触摸超过100ms，禁止');
        e.preventDefault(); // 阻止长按事件
      }, 50); // 100ms 内没有松开就算作长按
    },
    handleTouchEnd(e){
      clearTimeout(this.touchTimer); // 如果在 100ms 内松开手指，则不阻止长按
      this.touchTimer = null;
    },
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  },
  mounted(){
    bingCradForm.addEventListener('paste', (event) => {
      console.log('禁止粘贴');
      event.preventDefault(); // 阻止粘贴事件
    });
    bingCradForm.addEventListener('copy', (event) => {
      console.log('禁止复制');
      event.preventDefault(); // 阻止复制事件
    });
    bingCradForm.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    bingCradForm.addEventListener('dblclick', (event) => {
      event.preventDefault();
    });
    // bingCradForm.addEventListener('touchstart', function (e) {
    //   console.log('触摸');
    //   this.touchTimer = setTimeout(function () {
    //     console.log('触摸超过100ms，禁止');
    //     e.preventDefault(); // 阻止长按事件
    //   }, 100); // 100ms 内没有松开就算作长按
    // });
    // bingCradForm.addEventListener('touchend', function (e) {
    //   clearTimeout(this.touchTimer); // 如果在 100ms 内松开手指，则不阻止长按
    //   this.touchTimer = null;
    // });

  },
  beforeDestory(){
    bingCradForm.removeEventListener('paste', (event) => {
      console.log('禁止复制');
      event.preventDefault();
    });
    bingCradForm.removeEventListener('copy', (event) => {
      event.preventDefault();
    });
    bingCradForm.removeEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    bingCradForm.removeEventListener('dblclick', (event) => {
      event.preventDefault();
    });
  },
};
</script>

<style lang='less' scoped>


:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar {
    z-index: 999;
	position: relative;
    background: url(/img/header.svg) no-repeat 50%/100%;
}
.van-nav-bar__content{
	height:3rem;
}

.bind {
    width: 100%;
    height: 100%;
    background: #f0f0f0
}

.bind .van-field__control::-webkit-input-placeholder {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden
}

.bind .van-field__control::-moz-placeholder {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden
}

.bind .van-field__control:-ms-input-placeholder {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden
}

.bind .btn {
    width: 100%;
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center
}

.bind .btn a {
    width: 100%;
    background: linear-gradient(90deg,#f560cd,#4f2b8a);
    color: #fff;
    padding: .66667rem 0;
    border-radius: .33333rem
}

.bind .bank {
    width: 90%;
    height: 10rem;
    background: #705b65;
    border-radius: .66667rem;
    margin: 1rem auto;
    text-align: left;
    padding: 5%;
    position: relative;
    overflow: hidden
}

.bind .bank p {
    color: #ede7e7;
    padding: .13333rem 0;
    position: relative;
    z-index: 9
}

.bind .bank h1 {
    color: #fff;
    padding: .33333rem 0;
    position: relative;
    z-index: 9
}

.bind .bank span {
    position: absolute;
    right: .66667rem;
    bottom: .66667rem;
    color: #dfbb9e;
    z-index: 9
}

.bind .bank i {
    position: absolute;
    right: -3.33333rem;
    top: 0;
    color: #877f7e;
    font-size: 10rem;
    opacity: .4
}

.van-cell {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 0.6rem;
    overflow: hidden;
    color: #323233;
    font-size: 1rem;
    line-height: 2rem;
    background: #fff;
}

.van-cell__value {
    justify-content: space-between;
    align-items: center;
    display: flex
}

.van-field__body {
    width: 100%
}

.van-dropdown-menu__bar {
    height: auto;
    box-shadow: none
}

.van-dropdown-menu__item {
    justify-content: flex-start
}

.bind .van-cell__value .van-dropdown-menu__title {
    padding-left: 0
}
.bind .van-field .van-field__value{
	border:0;
}
.van-dropdown-menu__title {
    font-size: 1rem;
    line-height: 2rem;
}
::v-deep .van-field__body{
  input{
    -webkit-user-select: none;  /* 禁止文本选择 */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
