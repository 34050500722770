<template>
  <div class="info">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left van-haptics-feedback" @click="back()">
          <i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
        </div>
        <div class="van-nav-bar__title van-ellipsis">
          {{ $t('mine.infomation') }}
        </div>
      </div>
    </div>
    <van-cell-group>
      <van-cell style="padding: 1rem 1.5rem;display: flex;align-items: center;" :value="$t('more.fy46')"
        @click="userInfo.is_bet == 0 ? '' : openHerderImg()">
        <template #title>
          <van-image round width="3.2rem" height="3.2rem" :src="userInfo.header_img || baseInfo.ico">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
        </template>
      </van-cell>
      <van-cell :title="$t('infomation.username')" :value="userInfo.username" is-link />
      <van-cell :title="$t('infomation.name')" :value="userInfo.name" is-link
        @click="userInfo.is_bet == 0 ? '' : toSetName()" />
      <!-- <van-cell :title="$t('infomation.bankcard')" :value="$t('common.disable')" is-link  @click="toSetBank()" /> -->
      <van-cell :title="$t('infomation.phone')" :value="userInfo.phone" is-link
        @click="userInfo.is_bet == 0 ? '' : toSetSex()" />
    </van-cell-group>

    <van-popup v-model:show="show" position="bottom" :style="{ height: '40%' }">
      <div
        style="display: flex;align-items: center;flex-wrap: wrap;margin: 20px 10px;height: calc(100% - 7rem);overflow: auto;">
        <van-image v-for="(value,key) in avatarArr" :key="key" @click="select_header_img(value)" :src="value"
          :style="isActive == value ? 'border:3px solid #f560cd;' : 'border:3px solid transparent;'"
          style="margin: 5px;margin-bottom: 10px;" round width="4rem" height="4rem"></van-image>
      </div>

      <a class="btns" @click="check">{{ $t('more.fy10') }}</a>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      isActive: '',
      show: false,
      isBank: false,
      avatarArr: []
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      baseInfo: state => state.baseInfo
    })
  },
  methods: {
    back() {
      return window.history.back();
    },
    toSetName() {
      this.$router.push({ path: '/Setname' });
    },
    toSetBank() {
      this.$router.push({ path: '/Setbank' });
    },
    toSetSex() {
      this.$router.push({ path: '/Setsex' });
    },
    openHerderImg() {
      this.show = true;
    },
    select_header_img(url) {
      this.isActive = url;
    },
    getAvaratList() {
      this.$http({
        method: 'get',
        url: 'user_header_list'
      }).then(res => {
        if (res.code === 200) {
          this.avatarArr = res.data;
        }
      })
    },
    check() {
      this.$http({
        method: 'post',
        data: { header_img: this.isActive },
        url: 'user_header_img'
      }).then(res => {
        if (res.code === 200) {
          this.getUserInfo();
          this.$toast(res.msg);
          this.show = false;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.$store.commit('setUserInfoValue', res.data);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.isBank = true;
          } else {
            this.isBank = false;
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getUserBankInfo();
      this.getAvaratList();
    }
  }
};
</script>

<style lang='less' scoped>
:export {
  gbcolor: linear-gradient(30deg, #7d76ef, #d63f8c)
}

.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #4141d8;
}

.van-nav-bar {
  position: relative;
  z-index: 999;
  height: 3rem;
  line-height: 3rem;
  background: none
}

.info {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #fff, #fefae9)
}

.info .van-hairline--bottom:after {
  border-bottom-width: 0
}

.info .van-cell {
  padding: 1.5rem 1.5rem;
  background: none
}

.info .van-cell .van-cell__title {
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.info .van-cell .van-cell__title img {
  width: 1.5rem;
  margin-right: 6px
}

.info .van-cell .van-cell__value {
  color: #4141d8;
  line-height: 1.2rem;
  height: 1.2rem;
}

.info .van-nav-bar {
  background: none
}

.van-icon {
  font-size: 1rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.info .van-nav-bar .van-icon,
.info .van-nav-bar__title {
  color: #412188
}

.btns {
  display: inline-block;
  width: 90%;
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  padding: 0.86667rem 0;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 1.5rem;
  left: 5%;
}
.van-popup{
  width: 500PX;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 500px) {
    .van-popup {
        width: 100%;
    }
}
</style>
