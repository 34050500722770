<template>
    <div>
        <div id="dplayer"></div>
    </div>
</template>

<script>
import DPlayer from "dplayer";
// import Hls from "hls.js"
export default {
    props: ["url", "pic"],
    data() {
        return {
            options: {
                video: {
                    url: '', // 播放视频的路径
                    pic: '',
                    // type:'hls',
                    type: "customHls",
                    customType: {
                        customHls: function (video, player) {
                            const hls = new Hls();
                            console.log(video);
                            
                            // hls.loadSource(video.src);
                            // hls.attachMedia(video);

                             // 获取 source 元素中的 m3u8 地址
                            const source = video.querySelector('source');
                            if (source && source.src) {
                                console.log("HLS stream URL:", source.src);
                                hls.loadSource(source.src); // 使用 source 中的 m3u8 地址
                                hls.attachMedia(video);     // 连接 HLS 到 video 元素
                            } else {
                                console.error("No valid HLS stream source found!");
                            }

                            // 错误处理
                            hls.on(Hls.Events.ERROR, (event, data) => {
                                console.error('HLS.js 错误:', data);
                            });
                        },
                    },
                    thumbnails: "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=24336452,906724543&fm=26&gp=0.jpg" // 进度条上的缩略图,需要通过dplayer-thumbnails来生成
                }
            },
            maxTime: 6 * 60,//单位 秒
            dp: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            if (this.url.match(/\.mp4$/i)) {
                this.options.video.type = 'mp4';
            } else if (this.url.match(/\.m3u8$/i)) {
                this.options.video.type = 'customHls';
            }
            this.options.video.url = this.url;
            this.options.video.pic = this.pic;
            this.dp = new DPlayer({
                container: document.getElementById('dplayer'),
                hotkey: true, // 是否支持热键，调节音量，播放，暂停等
                preload: "auto", // 自动预加载
                volume: 0.7, // 初始化音量
                playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3], //可选的播放速度，可自定义,
                video:this.options.video,
                pluginOptions: {
                    hls: {
                        startLevel: -1,  // 自动选择最佳的流级别
                        capLevelToPlayerSize: true, // 根据播放器大小自动选择适合的流
                    },
                },
                lang:'en',
            });
            this.dp.on('play', () => {
                const currentTime = this.dp.video.currentTime;  // 获取视频当前播放时间
                console.log(this.dp.video.currentTime,this.maxTime);
                
                if (currentTime > this.maxTime) {
                    this.dp.pause();  // 暂停视频播放
                    this.$toast(this.$t('more.fy42'));
                }
                console.log('播放');
            })
            // 禁用进度条拖动
            this.dp.on('seeked', (e) => {
                const currentTime = this.dp.video.currentTime;  // 获取视频当前播放时间
                console.log('seeked', currentTime);
                if (currentTime > this.maxTime) {
                    // 阻止进度条改变事件
                    e.preventDefault();
                    this.dp.seek(this.maxTime);  // 恢复进度条到最大时间
                }
            });
            this.dp.on('timeupdate', () => {
                const currentTime = this.dp.video.currentTime;  // 获取视频当前播放时间

                // 判断视频是否播放超过最大限制
                if (currentTime > this.maxTime) {
                    this.dp.pause();  // 暂停视频播放
                    this.$toast(this.$t('more.fy42'));
                }
            });
        })
    },
    methods: {

    }
};
</script>

<style scoped>
#dplayer {
    width: 100%;
    /* height: 56.25vw; */
    height: 65vw;
    /* 默认16:9比例 */
}
</style>