export default {
	Language: '中文',
	system: {
		homePage: {
			title1: '标题1',
			title2: '标题2',
			description1: '近7日',
			description2: '近6个月',
		}
	},
	login:{
		register:'注册会员',
		usernamePls:'请输入用户名',
		passwordPls:'请输入密码',
		toRegister:'没有账号吗？"现在注册会员吧',
		doLogin:'登录',	  
	},
	register:{
		repasswordPls:'请输入取款密码',  
		codePls:'请输入邀请代码',  
		register:'注册',
		xieyi:'同意使用条款',
		xieyiPls:'请选择下面的开户协议！',
	},
	mine:{
		xyfs:'信用分数',
		ljjf:'累计积分',
		recharge:'充值',
		withdraw:'提现',
		money:'可用余额',
		infomation:'基本信息',
		moneylog:'积分记录',
		withdrawrecord:'提款明细',
		gamerecord:'礼品记录',
		notice:'公告事项',
		mymove:'观看记录',
		loginpwd:'登录密码',
		paypwd:'支付密码',
		loginout:'注销',
		paypwdhasseted:'已设置取款密码。如果需要修改，请与客户中心联系',
		setbank:'请设置收款卡！',
		lorr:'登录/注册',
	},
	infomation:{
		username:'会员ID',
		name:'姓名',
		bankcard:'提款账户',
		phone:'手机号码',
		recharge:'充值',
		amount:'拥有点',
		toserve:'请联系客户服务',
		kfms:'想要线下充电，请向客服咨询。部分结算渠道的充值金额将随机充值至小数点1位，结算时请务必确认',
	},
	withdraw:{
		title:'提现',
		log:'取款记录',
		method:'取款方法',
		avmoney:'可提现余额',
		limit:'取款限度',
		submit:'提款申请',
		xiane:'单笔限额',
		numbers:'取款次数',
		time:'到账时间',
		zuidi:'最少',
		zuigao:'最高',
		numsmax:'每天最多提取现金',
		ci:'次',
		timedetail:'一般汇款时间为5分钟左右，最快在2分钟内汇款。',
		bank:'银行卡',
		status1:'待审核',
		status2:'成功',
		status3:'失败',
	},
	moneylog:{
		title:'资金明细',
		type1:'变更',
		type2:'彩金',
		type3:'任务',
		type4:'奖金',
		type5:'取款',
		type6:'取款失败',
	},
	setting:{
		loginpwdtitle:'更改登录密码',
		oldpwd:'请输入旧密码',
		newpwd:'请输入新密码',
		renewpwd:'请重新输入新密码',
		change:'更改',
		plseall:'请填写完整表格！',
		pswwrong:'两次密码输入不匹配',
		paypwdtitle:'设置资金密码',
		paypwd:'请输入资金密码',
		repaypwd:'请重新输入资金密码',
	},
	home:{
		gonggao:'享受终极奢华体验',
		qgkf:'全国可能',
		hotlist:'人气推荐列表',
		hotgame:'任务推荐',
		gaoji:'高级',
		smrz:'实地认证',
		videorz:'本人视频认证',
		star:'评分',
		sg:'身高',
		xw:'胸围',
		diqu:'可服务区域',
		apply:'申请约会',
		ssxz:'实时现状',
		joinvip:'加入了会员',
	},
	video:{
		title:'影像馆',
		nums:'播放量',
		bofangliang:'播放量',
		hotlist:'人气推荐',
		plsrefisrt:'请充值后看视频！',
	},
	choose:{
		title:'最好的一夜',
		nav:'选妃',
		apply:'匹配申请',
		jianjie:'美女简介',
		move:'是否可移动',
		click:'点击认证',
		detail:'详细信息',
		videodetail:'实时视频',
	},
	common:{
		refresh:'刷新成功',
		noallow:'功能已禁用！',
		disable:'禁用',
		all:'全部',
		nomore:'没有更多了。',
		nodata:'无数据',
		submit:'提交',
		more:'查看更多',
		pinglunnav:'评论',
		pingluntitle:'评论',
		pldtitle:'评论详情',
		minenav:'账户',
		homenav:'主页',
		addbank:'添加收款卡',
		setbank:'提款账户',
	},
	game:{
		expect:'期号',
		jieguo:'数据',
		da:'大',
		xiao:'小',
		dan:'单',
		shuang:'双',
		大:'大',
		小:'小',
		单:'单',
		双:'双',
		order:'任务单',
		clear:'清空订单',
		submit:'确认提交',
		kjcc:'开奖成功，期号:',
		lxgly:'请与您的管理员联系.',
		plsmoney:'请填写金额!',
		plsnum:'请选择号码!',
		moneynote:'余额不足，请联系客服充值!',
		lefttime:'剩余时间',
		lunci:'期号',
		switch:'切换',
		fy1:'当前选择',
		fy2:'数量',
		fy3:'票数',
		fy4:'总金额',
		fy5:'可用积分',
		fy6:'认证',
		fy7:'未选中',
	},
	kefu:{
		title:'在线客服',
		lianxi:'联系',
		content:'全天7 * 24小时竭诚为您服务',
	},
	setname:{
		title:'修改实名',
		xingming:'名字',
		tips:'为了您的账户安全，实际名称必须与绑定银行卡名称匹配',
		xmpls:'请输入实际名称.',
		save:'保存',
		notreset:'不要重复设置！',
		plsentername:'请输入名称！',
	},
	bank: {
		nameRequired: '请先设置姓名后再绑定银行卡！',
		payPasswordRequired: '请先设置提款密码后再绑定银行卡！',
		bindCardTitle: '提款账户登记',
		selectBankCard: '请选择银行卡',
		accountNumber: '账号',
		enterAccountNumber: '请输入银行卡号',
		accountHolder: '账户名',
		enterRealName: '请输入真实姓名',
		withdrawalPassword: '提款密码',
		enterWithdrawalPassword: '请输入提款密码',
		addBankCard: '添加银行卡',
		enterBankCardNumber: '请输入银行卡号！',
		selectBank: '请选择银行！',
		bankCard: '银行卡',
	},
	metaTitles:{
		title1: '浪漫',
		title2: '浪漫',
		title3: '浪漫-个人资料',
		title4: '经理评价',
		title5: '经理评价',
		title6: '个人资料',
		title7: '个人资料',
		title8: '浪漫-我的',
		title9: '视频',
		title10: '游戏',
		title11: '登录',
		title12: '注册',
		title13: '在线客服',
		title14: '在线客服',
		title15: '设置',
		title16: '个人资料',
		title17: '实名修改',
		title18: '性别修改',
		title19: '支付密码管理',
		title20: '登录密码管理',
		title21: '详细信息页面',
		title22: '浪漫-信息公告',
		title23: '视频播放',
		title24: '收款卡填写',
		title25: '收款卡填写',
		title26: '现金提现',
		title27: '充值',
		title28: '个人报表',
		title29: '现金提现记录',
		title30: '游戏记录',
		title31: '游戏详情',
		title32: '余额记录',
		title33: '浪漫-我的电影'
	},
	more:{
		fy1:'可能',
		fy2:'拇指',
		fy3:'安全策略',
		fy4:'为了保护会员的个人信息，Romantic仅接受通过个人认证服务或已完成认证的会员推荐的会员入会。',
		"fy5": "操作记录",
		"fy6": "订单内容",
		"fy7": "每{count}个句点",
		"fy8": "所有操作",
		"fy9": "重置",
		"fy10": "确定",
		"fy11": "全部",
		"fy12": "奖金积分",
		"fy13": "结果等待中",
		"fy14": "任务金明细",
		"fy15": "任务结果",
		"fy16": "分",
		"fy17": "操作积分",
		"fy18": "礼物已完成。",
		"fy19": "下单时间",
		"fy20": "详细信息",
		"fy21": "内容",
		"fy22": "金额",
		"fy23": "结果",
		"fy24": "时间",
		"fy25": "胜利",
		"fy26": "任务失败",
		fy27:'系统通知',
		fy28:'我的电影',
		fy29:'完成任务获得更多电影票',
		fy30:'电影胶片',
		fy31:'关注列表',
		fy32:'尚无数据',
		fy33:'输入您的提现密码。',
		fy34:'密码',
		fy35:'请输入准确金额。',
		fy36:'请选择用于提现的银行卡。',
		fy37:'请输入您的支付密码。',
		fy38:'通知',
		fy39:'现有手机号码',
		fy40:'新电话号码',
		fy41:'修改手机号码',
		fy42:'请联系经理进行认证',
		fy43:'账号下线',
		fy44:'获取系统配置失败，请检查网络连接',
		fy45:'您目前的网络异常，请更换网络',
		fy46:'更换头像',
	}
  
};
