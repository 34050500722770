<template>

  <div class="bg">
    <div class="register">
		<div class="head">
			<i class="van-badge__wrapper van-icon van-icon-cross"  @click="back()"></i>
			<div style="display: flex; align-items: center;">
				<a @click="toLogin()" >{{$t('login.doLogin')}}</a>
				
			</div>
		</div>
		<!-- <img src="../../assets/img/logo.png" alt="" style="margin: 10px 0px;"> -->
     <img style="width: 112px;height: 112px;margin: 1.5rem 0;border-radius: 10px;" src="../../assets/img/logo2a.png" alt="" >
		<form>
			<div class="ipt" >
				<van-icon name="contact" size="1.5rem" color="#999" />
				<input type="text" v-model="username" :placeholder="$t('login.usernamePls')" >
			</div>
			<div class="ipt" >
				<van-icon name="lock" size="1.5rem" color="#999" />
				<input autocomplete=""  :placeholder="$t('login.passwordPls')" v-model="password" :type="passwordType" >
				<van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" size="1.5rem" color="#999" @click="switchPasswordType"/>
			</div>
			<!-- <div class="ipt" >
				<van-icon name="lock" size="1.5rem" color="#999" />
				<input autocomplete=""  :placeholder="$t('register.repasswordPls')" v-model="paypassword" :type="repasswordType" >
				<van-icon :name=" repasswordType === 'password' ? 'closed-eye':'eye-o'" size="1.5rem" color="#999" @click="switchrePasswordType"/>
			</div> -->
			<div class="ipt" >
				<van-icon name="medal-o" size="1.5rem" color="#999" />
				<input type="text" v-model="code" :placeholder="$t('register.codePls')" >
			</div>
		</form>
		<div class="res" >
			<van-checkbox v-model="checked" />
			<a >{{$t('register.xieyi')}}</a>
		</div>
		<div class="btn" :class="btnState==false?'':'active'" @click="doRegister()" >{{$t('register.register')}}</div>
      
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      password: this.inputValue,
      passwordType: 'password',
	    paypassword: this.inputValue,
		  repasswordType: 'password',
    };
  },
  computed:{
        btnState(){
            return this.username!==""&&this.password!==""
            // &&this.paypassword!==""
            &&this.code!==""
        }
    },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchrePasswordType() {
      this.repasswordType = this.repasswordType === 'password' ? 'text' : 'password'
    },
	toLogin(){
	  this.$router.push("Login")
	},
    back(){
      return window.history.back();
    },
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast(this.$t('login.usernamePls'));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast(this.$t('login.passwordPls'));
        return false;
      }
      // if(this.paypassword === "" || this.paypassword === null || this.paypassword === undefined){
      //   this.$toast.fail(this.$t('register.repasswordPls'));
      //   return false;
      // }
      if(this.code === "" || this.code === null || this.code === undefined){
        this.$toast.fail(this.$t('register.codePls'));
        return false;
      }
      if(!this.checked){
        this.$toast.fail(this.$t('register.xieyiPls'));
        return false;
      }
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
          // paypassword:this.paypassword,
          code:this.code
        },
        url: 'member_register'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data)
            this.$router.push("Mine")
          }else {
            this.$toast.fail(res.msg);
          }
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/basenew.css";

.page {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.bg {
    height: 100%;
    background-image: linear-gradient(180deg,#f560cd,#4f2b8a)
}

.register {
    width: 100%;
    height: 100%;
    background: url(../../assets/img/bg.png) no-repeat 50%/100%;
    padding-bottom: 3.33333rem;
    position: relative;
    z-index: 1;
	  background-position: 72% 3rem;
}

.register .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4% 3%
}

.register .head i {
    font-size: 1.66667rem;
    color: #fff
}

.register .head a {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600
}

.register> {
    position: relative;
    z-index: 9
}

/* .register:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(30deg,rgba(125,118,239,.25),rgba(225,70,149,.18));
    left: 0;
    top: 0
} */

.register>img {
    width: 12rem;
    margin-top: .33333rem
}

.register .title {
    font-size: 1.16667rem;
    color: #fff;
    font-weight: 600;
    padding: 1.66667rem 0
}

.register .ipt {
    width: 90%;
    height: 3.66667rem;
    margin: 1rem auto;
    border-radius: .33333rem;
    overflow: hidden;
    background: hsla(0,0%,100%,.85);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 .66667rem
}

.register .ipt input {
    flex: 1;
    height: 100%;
    border: none;
    background: none;
    outline: none;
    text-align: left;
    font-size: .93333rem;
    padding-left: 1rem
}

.register .ipt input::-moz-placeholder {
    font-size: 1rem
}

.register .ipt input::placeholder {
    font-size: 1rem
}

.register .forget {
    width: 100%;
    text-align: right;
    padding: 0 1.33333rem
}

.register .forget a {
    color: #fff;
    font-size: .8rem
}

.register .res {
    width: 100%;
    padding: 0 1.33333rem;
    display: flex;
    justify-content: center;
    align-items: center
}

.register .res a {
    color: #fff;
    font-size: .8rem;
    margin-left: .33333rem
}

.register .btn {
    width: 90%;
    margin: 1.66667rem auto;
    background: #cacacc;
    height: 2.93333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: .33333rem;
    font-size: 1.06667rem
}

.register .btn.active {
    background: linear-gradient(90deg,#f560cd,#4f2b8a)
}

.register .van-nav-bar {
    background: none
}

.register .van-nav-bar .van-icon {
    color: #fff
}

.register .van-hairline--bottom:after {
    border-width: 0
}

</style>
