import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'; // 导入 i18n 配置
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import Pl from '../pages/pl/index.vue'/* 评论 */
import PinglunDetail from '../pages/pl/PinglunDetail.vue'/* 评论 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo  from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank  from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard  from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw  from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Recharge  from '../pages/mine/Recharge'/* 绑定银行卡界面 */
import Personalreport  from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord  from '../pages/mine/GameRecord'/* 游戏记录 */
import GameDetail  from '../pages/mine/GameDetail'/* 游戏记录 */
import WithdrawRecord  from '../pages/mine/WithdrawRecord'/* 提现记录 */
import MoneyLog  from '../pages/mine/MoneyLog'/* 提现记录 */
import Mymove  from '../pages/mine/Mymove'/* 提现记录 */






Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:i18n.t('common.homenav')}},
    {path:'/Home',name:'home',component:Home,meta:{title:i18n.t('common.homenav')}},
	{path:'/Choose',name:'choose',component:Choose,meta:{title:i18n.t('choose.nav')}},
	{path:'/Pl',name:'pl',component:Pl,meta:{title:i18n.t('metaTitles.title4')}},
	{path:'/PinglunDetail',name:'PinglunDetail',component:PinglunDetail,meta:{title:i18n.t('metaTitles.title4')}},
	{path:'/List',name:'list',component:List,meta:{title:i18n.t('metaTitles.title6')}},
	{path:'/Profile',name:'profile',component:Profile,meta:{title:i18n.t('metaTitles.title6')}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:i18n.t('common.minenav')}},
    {path:'/Video',name:'video',component:Video,meta:{title:i18n.t('video.title')}},
    {path:'/Game',name:'game',component:Game,meta:{title:i18n.t('metaTitles.title10')}},
    {path:'/Login',name:'login',component:Login,meta:{title:i18n.t('metaTitles.title11')}},
    {path:'/Register',name:'register',component:Register,meta:{title:i18n.t('metaTitles.title12')}},
    {path:'/ServiceOnline',name:'ServiceOnline',component:ServiceOnline,meta:{title:i18n.t('metaTitles.title13')}},
    {path:'/ServicePage',name:'ServicePage',component:ServicePage,meta:{title:i18n.t('metaTitles.title13')}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:i18n.t('metaTitles.title15')}},
    {path:'/Infomation',name:'Infomation',component:Infomation,meta:{title:i18n.t('mine.infomation')}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:i18n.t('setname.title')}},
    {path:'/Setsex',name:'Setsex',component:Setsex,meta:{title:i18n.t('more.fy41')}},
    {path:'/SetPayPassword',name:'SetPayPassword',component:SetPayPassword,meta:{title:i18n.t('setting.paypwdtitle')}},
    {path:'/SetLoginPassword',name:'SetLoginPassword',component:SetLoginPassword,meta:{title:i18n.t('setting.loginpwdtitle')}},
    {path:'/Certification',name:'Certification',component:Lottery,meta:{title:i18n.t('metaTitles.title21')}},
    {path:'/Notice',name:'Notice',component:Notice,meta:{title:i18n.t('mine.notice')}},
    {path:'/PlayVideo',name:'PlayVideo',component:PlayVideo,meta:{title:i18n.t('metaTitles.title23')}},
    {path:'/Setbank',name:'Setbank',component:Setbank,meta:{title:i18n.t('infomation.bankcard')}},
    {path:'/BindCard',name:'BindCard',component:BindCard,meta:{title:i18n.t('bank.bindCardTitle')}},
    {path:'/Withdraw',name:'Withdraw',component:Withdraw,meta:{title:i18n.t('withdraw.title')}},
    {path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:i18n.t('metaTitles.title27')}},
    {path:'/Personalreport',name:'Personalreport',component:Personalreport,meta:{title:i18n.t('metaTitles.title28')}},
    {path:'/WithdrawRecord',name:'WithdrawRecord',component:WithdrawRecord,meta:{title:i18n.t('mine.withdrawrecord')}},
    {path:'/GameRecord',name:'GameRecord',component:GameRecord,meta:{title:i18n.t('mine.gamerecord')}},
    {path:'/GameDetail',name:'GameDetail',component:GameDetail,meta:{title:i18n.t('more.fy15')}},
    {path:'/MoneyLog',name:'MoneyLog',component:MoneyLog,meta:{title:i18n.t('moneylog.title')}},
    {path:'/Mymove',name:'Mymove',component:Mymove,meta:{title:i18n.t('mine.mymove')}},


];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //修改标题路由配置加上这个
    // document.title = to.matched[0].meta.title
    next()
})

export default router