<template>
<div class="mymovie">
	<div class="van-nav-bar van-hairline--bottom">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback" @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
				</div>
			<div class="van-nav-bar__title van-ellipsis">
				{{ $t('mine.mymove') }}
			</div>
		</div>
	</div>
	<div class="top">
		<div class="left">
			<div class="van-image van-image--round" style="width: 60px; height: 60px;">
				<img src="https://ma.xexexoox10svip1x.com/pic/avatar1.png" class="van-image__img"
				style="object-fit: cover;">
				
				</div>
			<div class="center">
				<h1>
					Hi,{{userInfo.username}}
				</h1>
				<p>
					{{ $t('more.fy29') }}
				</p>
			</div>
		</div>
		<div class="right">
			{{ $t('more.fy30') }}:{{userInfo.video_money}}
		</div>
	</div>
	<div class="item">
		<div class="title">
			<span>
				<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFRUlEQVRYhZ2YW6hdVxWGv29nW2pytBg9PhSt4osoQitFsGAstqgVC2qjiVBBTUtBgqBUCEVQfFBBqRp88vbgQ0GxtehDEyJNTrw0pUJJqQWxVXxRS3LUVmnEmLN+H9bca8912SdHYR/OmnuOOS7/+MeYY21//tFjmCAgKc9BuEZyO8nNwh7Jr00eFn4muQDBlDOtPCzPYgILneU/lR0mZCDMG2ZoURRQkbxHcr9xN8u9t6qflJw3PCCeijkpbBpbGSWLoFo9BIrjVPtlr8iUTytz8mMnuohKtG8UnirrZyE/NGxK3ix5t2FPhcolkgckPxZOmZzvou/Q7iPS36e3Bpg3znoRSb5e1r+R7CN5rkVQTPZq9os3S24yWUcOigdNtpQfiA9LTiT8qdPZIcJCT+uCi/RWCJ34+MmaB2sm/2y9zTsMG93emGcvEm6BHDC5Sbh6IHesoHta8sc+z5bcI30OefzQBpXR/ZL7gb+ZvHz5fUXeHpFrhXmT4QOS/ZJrl8QOkjMt7/Kg8AdG6az0PnTH6VrxMcktJg8Kt0nmDiqnrqpisBGaak/DPsntkn3AGwY6zkjuAzYkTy0co+zPtzoO5WrxxvL8TsnzxqoCezzr1l20LT8akxc058WLkqvMsprKmRskN0hIfFLzI8lR4z9C8Cd3/grJPslxk90T/Wi8XqZikM4+P5ZVNMVBah1/lXxQsjFvnO2VnDC5UrMp+bT4uw7+RZW44EyFVItmVzlLNBd9aUFk+3vt85b4iiT3aN4unjJ53bzRI8KV4p9bMmazTg/bGZ1KZy2/8jyV0xwPbpjcqHxt3jh7bxG8T9zkfzJKXwY6RxYpo8+fVU4fUR+V3DrfYrZeFD638tAqJ7u/8ZnVgUG/OATzTNmbzxu9VIxeUSnYo7lK0oyqq0ZiwihjNNRsied68tV54IrF+XnjLEVBisFPSL4irknSQ6HnQAbpWImmki2T4+qtw7ZRUjZbnG/vsr7RazRrRaHbO7BDcie7NNePkRmfr8cPi8J7JA+ZrGFymeraNYlMTey2LTaSx5aOtvf7VHHMGx0pJP5iB1EPyD3u4sOKZAdo9saPQsi3aQ6Lu02aFVHPhAviVyWPTzk6lY6d9LM6ZaEtybskH67up20iytPGx1dU1woHVsiUYEvKgCWBP2N8QvPiulcM+wjkXybf//+6+AjxmkOzUkGd4nOae6ea3VJuh9U11bumy77iEC4H8tGIQc/QZfvOiqh33sVT9aG2hS8dwdEhJpycmpG2d2B7nrUc6ryE9O4qVyvuoTHmED0HVl81QyerKqs5Ml1Z1IoZRsrAaVYanexdZT2P7Y3VGmiVZjHddQa7yppIU9/oJM+GTlymD6lp+3Mn3A6XLRJ9LmWcznXh88Z1zCO28zGa2yQHJf8xfkHztOSlJp9TX237an5vuZ62ag7tKvBezCAN3XBewU9/2kN8v8nhds8DJkdLyr4j7i2GnhcPm7xLc3e5ng5ovin5t8nFjkPBF9Ki8MqW1PTSlV7FWRzt8eyn6rrkVSS/rNL5IZP3qRck3y2pPgk5YvIazRnxYnH4uoL6ll+6+y/fEw6R/F14i+T33RtB/Xaw3S8dI9nq+8HZ3pvrUsdZybUmj8zD7Fshh9SXQc4Gvmg4K1yKdm8dWH7J6Lgj6RM70n6q1pAyMTK47RvjTPNayZ2tM6L57LzRx4RPJXxDWBO/nDo97Zze8ajPs0L+QZlnzLPh9dSvwHaq+Ii4MS++H0V+C9yV8HrhJaVHdr2lNjzm2cL4Sp4NrycljeSc4bT6bckzJvwXm3g8AV5f3OgAAAAASUVORK5CYII="
				alt="">
				{{ $t('more.fy31') }}
			</span>
		</div>
		<div class="van-pull-refresh">
			<div class="van-pull-refresh__track" style="transition-duration: 0ms;">
				<div class="van-pull-refresh__head"></div>
				<div role="feed" class="van-list" aria-busy="false">
					
					<div class="van-empty" style="margin-top: 100px;">
						<div class="van-empty__image" style="width: 80px; height: 80px;">
							<img src="img/z35.png"></div>
						<p class="van-empty__description">
							{{ $t('more.fy32') }}
						</p>
						</div>
					
					
					
					<div class="van-list__placeholder"></div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      isActive:false,
      show:false,
      isBank:false,
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    toSetName(){
      this.$router.push({path:'/Setname'});
    },
    toSetBank(){
      this.$router.push({path:'/Setbank'});
    },
    toSetSex(){
      this.$router.push({path:'/Setsex'});
    },
    openHerderImg(){
      this.show = true;
    },
    select_header_img(url){
        this.isActive = url;
    },
    check(){
      this.$http({
        method: 'post',
        data:{header_img:this.isActive},
        url: 'user_header_img'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.$toast(res.msg);
          this.show = false;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.$store.commit('setUserInfoValue', res.data);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.isBank = true;
          }else {
            this.isBank = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.hot {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap
}

.hot li {
    width: calc(50% - .33333rem);
    border-radius: .33333rem;
    overflow: hidden;
    margin-top: .66667rem;
    height: 6.66667rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.hot li img {
    height: 100%
}

.hot li>div {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.4);
    padding: .33333rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.hot li>div span {
    color: #fff;
    font-size: .8rem;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 .33333rem
}

:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.mymovie {
    width: 100%;
    height: 100%;
    background: #f0f0f0
}

.mymovie .top {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: .13333rem;
    padding: 3%;
    margin: 1rem auto
}

.mymovie .top .van-image {
    border: 4px solid #d4d0e6
}

.mymovie .top .right {
    color: #fff;
    font-size: .76667rem;
    padding: .46667rem 1rem;
    border-radius: 2rem;
    background: linear-gradient(30deg,#8073eb,#e055a0)
}

.mymovie .top .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    flex: 1
}

.mymovie .top .left .center {
    padding-left: 5%
}

.mymovie .top .left .center> {
    padding: .2rem 0
}

.mymovie .top .left h1 {
    font-size: .93333rem;
    color: #48328b
}

.mymovie .top .left p {
    color: #523e90;
    font-size: .76667rem
}

.mymovie .item {
    width: 100%;
    margin: 1rem auto .33333rem;
    background: #fff;
    padding: 5%;
    height: calc(100% - 10.26667rem)
}

.mymovie .item .title {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.mymovie .item .title span {
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #4a338b
}

.mymovie .item .title span img {
    width: 1.33333rem;
    margin-right: .33333rem
}

.mymovie .item .title a {
    color: #979799
}

</style>