import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import { Locale } from 'vant';
// 引入中文语言包
// import zhCN from 'vant/es/locale/lang/zh-CN';
// Locale.use('zh-CN', zhCN);
// 引入韩文语言包
// import koKO from 'vant/es/locale/lang/ko-KO';
// Locale.use('ko-KO', koKO);
import jaJP from 'vant/es/locale/lang/ja-JP';
Locale.use('ja-JP', jaJP);
import 'video.js/dist/video-js.css'
import i18n from './i18n';

import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import '@vant/touch-emulator';

// 添加全局错误处理，捕获vue-dplayer的错误
window.addEventListener('error', function(event) {
  if (event.filename && event.filename.includes('vue-dplayer.js')) {
    console.warn('捕获到vue-dplayer错误:', event.message);
    event.preventDefault(); // 阻止错误继续传播
    return false;
  }
}, true);
console.log(process.env);

// 修改VueDPlayer的默认配置
Vue.use(VueDPlayer, {
  videoInit: {
    currentTime: 0,
    autoplay: false,
    muted: false,
    preload: 'metadata'
  }
});

// 重写HTMLMediaElement的currentTime setter，防止设置非法值
try {
  const originalDescriptor = Object.getOwnPropertyDescriptor(HTMLMediaElement.prototype, 'currentTime');
  if (originalDescriptor && originalDescriptor.set) {
    const originalSetter = originalDescriptor.set;
    
    Object.defineProperty(HTMLMediaElement.prototype, 'currentTime', {
      get: originalDescriptor.get,
      set: function(value) {
        if (typeof value === 'number' && isFinite(value)) {
          originalSetter.call(this, value);
        } else {
          console.warn('尝试设置非法的currentTime值:', value);
        }
      },
      configurable: true
    });
    
    console.log('已安装HTMLMediaElement.currentTime保护');
  }
} catch (error) {
  console.error('安装HTMLMediaElement.currentTime保护失败:', error);
}

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
