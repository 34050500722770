<template>
  <div class="password bl">
    <van-nav-bar :title="$t('setting.loginpwdtitle')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#735db2" @click="back()"/>
      </template>
    </van-nav-bar>
	<div class="van-cell-group van-hairline--top-bottom newPas">
		<div class="van-cell van-field">			
			<div class="van-cell__title van-field__label">
				<label id="van-field-75-label" for="van-field-75-input">
					{{$t('setting.oldpwd')}}
				</label>
				</div>
			<div class="van-cell__value van-field__value">
				<div class="van-field__body">
					<input :type="passwordType" v-model="old_password" class="van-field__control"
					:placeholder="$t('setting.oldpwd')" aria-labelledby="van-field-75-label">					
					<div class="van-field__right-icon">
						<van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" size="1.5rem"  @click="switchPasswordType"/>
					</div>
				</div>				
			</div>			
		</div>
		<div class="van-cell van-field">
			<div class="van-cell__title van-field__label">
				<label id="van-field-76-label" for="van-field-76-input">
					{{$t('setting.newpwd')}}
				</label>
				</div>
			<div class="van-cell__value van-field__value">
				<div class="van-field__body">
					<input :type="passwordType2" v-model="o_new_password" class="van-field__control" :placeholder="$t('setting.newpwd')">
					
					<div class="van-field__right-icon">
						<van-icon :name=" passwordType2 === 'password' ? 'closed-eye':'eye-o'" size="1.5rem"  @click="switchPasswordType2"/>
					</div>
				</div>
			</div>
		</div>
		<div class="van-cell van-field">
			<div class="van-cell__title van-field__label">
				<label id="van-field-77-label" for="van-field-77-input">
					{{$t('setting.renewpwd')}}
				</label>
				</div>
			<div class="van-cell__value van-field__value">
				<div class="van-field__body">
					<input :type="passwordType3" v-model="t_new_password" class="van-field__control"
					:placeholder="$t('setting.renewpwd')" aria-labelledby="van-field-77-label">
					<div class="van-field__right-icon">
						<van-icon :name=" passwordType3 === 'password' ? 'closed-eye':'eye-o'" size="1.5rem"  @click="switchPasswordType3"/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="btns"  @click="save()"><a>{{$t('setting.change')}}</a></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      o_new_password:"",
      t_new_password:"",
      old_password:"",
	  passwordType: 'password',
	  passwordType2: 'password',
	  passwordType3: 'password',
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
	switchPasswordType() {
	  this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
	},
	switchPasswordType2() {
	  this.passwordType2 = this.passwordType2 === 'password' ? 'text' : 'password'
	},
	switchPasswordType3() {
	  this.passwordType3 = this.passwordType3 === 'password' ? 'text' : 'password'
	},
    save(){
      if(this.o_new_password === "" || this.o_new_password === null || this.o_new_password === undefined){
        this.$toast.fail(this.$t('setting.plseall'));
        return false;
      }
      if(this.t_new_password === "" || this.t_new_password === null || this.t_new_password === undefined){
        this.$toast.fail(this.$t('setting.plseall'));
        return false;
      }
      if(this.old_password === "" || this.old_password === null || this.old_password === undefined){
        this.$toast.fail(this.$t('setting.plseall'));
        return false;
      }
      if(this.o_new_password !== this.t_new_password){
        this.$toast(this.$t('setting.pswwrong'));
        return false;
      }
      this.$http({
        method: 'get',
        data:{
          old_password:this.old_password,
          new_password:this.o_new_password,
        },
        url: 'user_set_loginpw'
      }).then(res=>{
        if(res.code === 200){
          this.$toast(res.msg);
          setTimeout(() => {
            localStorage.clear()
            this.$router.push("Login")
          }, 500);

        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.$store.commit('setUserInfoValue', res.data);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar {
	position: relative;
    z-index: 999;
	height:3rem ;
	line-height: 3rem;
    background: none
}
.van-nav-bar__content{
	height:3rem;
}
.password {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    background: linear-gradient(180deg,#fff,#fefae9)
}

.password .btns {
    display: inline-block;
    width: 90%;
    margin: 2rem auto;
    background: linear-gradient(20deg,#9d50cf,#ee5380);
    color: #fff;
    padding: .86667rem 0;
    border-radius: 3rem;
    font-size: .93333rem;
    cursor: pointer
}

.password .van-hairline--bottom:after {
    border-bottom-width: 0
}

.password .van-cell {
    padding: 1.2rem 1.2rem;
    background: none
}

.password .van-cell .van-cell__title {
    display: flex;
    justify-content: flex-start;
    align-items: center
}
.van-field{
	width: 100%;
}
.password .van-cell .van-cell__title img {
    width: 20px;
    margin-right: 6px
}

.password .van-cell .van-cell__value {
    color: #735db2
}

.password .van-nav-bar {
    background: none
}

.password .van-nav-bar .van-icon,.password .van-nav-bar__title {
    color: #412188
}

.password .van-cell-group .van-icon {
    font-size: 24px;
    color: #f53d81
}

</style>
