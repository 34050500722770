<template>
  <div class="bg">
      <!-- <img class="bg-img" src="img/login/login-bg.png"> -->
      <div class="login">
		<div class="head">
			<!-- <i class="van-badge__wrapper van-icon van-icon-cross"  @click="back()"></i> -->
			<i></i>
			<div style="display: flex; align-items: center;">
				<a @click="toRegister()" >{{$t('login.register')}}</a>
				<!-- <ChangeLanguage /> -->
			</div>
		</div>
		<img style="border-radius: 10px;" src="../../assets/img/logo2a.png" alt="" >
		<form>
			<div class="ipt" >
				<van-icon name="contact" size="1.5rem" color="#999" />
				<input type="text" v-model="username" :placeholder="$t('login.usernamePls')" >
			</div>
			<div class="ipt" >
				<van-icon name="lock" size="1.5rem" color="#999" />
				<input autocomplete=""  :placeholder="$t('login.passwordPls')" v-model="password" :type="passwordType" >
				<van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" size="1.5rem" color="#999" @click="switchPasswordType"/>
			</div>
		</form>
		<div class="forget" ></div>
		<div class="res" ><a @click="toRegister()" >{{$t('login.toRegister')}}</a></div>
		<div class="btn" :class="btnState==false?'':'active'" @click="doLogin()" >{{$t('login.doLogin')}}</div>
		
      </div>
    </div>
</template>

<script>
import ChangeLanguage from '@/i18n/change-language'
import { Toast } from 'vant';
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  components: {
      ChangeLanguage,
    },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      username: '',
      password: this.inputValue,
      passwordType: 'password',
    };
  },
  computed:{
         btnState(){
             return this.username!==""&&this.password!==""
         }
     },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    toRegister(){
      this.$router.push("Register")
    },
	onSelect(action) {
		Toast(action.text);
	},
    doLogin() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast(this.$t('login.usernamePls'));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast(this.$t('login.passwordPls'));
        return false;
      }
      this.$http({
        method: 'post',
        data:{username:this.username,password:this.password},
        url: 'member_login'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data.id)
            this.$router.push("Mine")
          }else {
            this.$toast(res.msg);
          }
      })
    }
  },
  created() {
      if(localStorage.getItem('token')){
        return window.history.back();
      }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/basenew.css";

.page {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-popover__action {
	width: 2rem;
	height: 5.867vw;
	padding: 0 2.133vw;
	font-size: 1.867vw;
	line-height: 2.667vw;
	cursor: pointer;
}
.bg {
    left: 0;
    right: 0;
    margin: auto
}

.bg,.video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0
}

.video {
    display: flex;
    justify-content: center;
    align-items: center
}

.video video {
    height: 100%;
    left: -10rem;
    position: relative
}

.bg {
    background-image: linear-gradient(180deg,#f560cd,#4f2b8a)
}

.login {
    width: 100%;
    height: 100%;
    background: url(../../assets/img/bg.png) no-repeat 50%/100%;
    position: relative;
    z-index: 99;
	  background-position: 72% 3rem;
}

.login .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4% 3%
}

.login .head i {
    font-size: 1.66667rem;
    color: #fff
}

.login .head a {
    font-size: .86667rem;
    color: #fff;
    font-weight: 600;
    margin-right: .5rem
}

.login>img {
    width: 7rem;
	margin: 3.5rem 0px;
    margin-top: 3.5rem
}

.login .title {
    font-size: 1.16667rem;
    color: #fff;
    font-weight: 600;
    padding: 1.66667rem 0;
    opacity: 0
}

.login .ipt {
    width: 90%;
    height: 3.66667rem;
    margin: 1rem auto;
    border-radius: .33333rem;
    overflow: hidden;
    background: hsla(0,0%,100%,.85);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.33333rem
}

.login .ipt input {
    flex: 1;
    height: 100%;
    border: none;
    background: none;
    outline: none;
    text-align: left;
    font-size: .93333rem;
    padding-left: 1rem
}

.login .ipt input::-moz-placeholder {
    font-size: 1rem
}

.login .ipt input::placeholder {
    font-size: 1rem
}

.login .forget {
    width: 100%;
    text-align: right;
    padding: 0 1.33333rem
}

.login .forget a {
    color: #fff;
    font-size: .8rem
}

.login .res {
    width: 100%;
    padding: 0 1.33333rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.login .res a {
    color: red;
    font-size: .93333rem
}

.login .res a:last-child {
    padding: .26667rem .66667rem;
    margin-top: .66667rem;
    border-radius: .13333rem
}

.login .btn {
    width: 90%;
    margin: 1.66667rem auto;
    background: #cacacc;
    height: 2.93333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: .33333rem;
    font-size: 1.06667rem
}

.login .btn.active {
    background: linear-gradient(90deg,#f560cd,#4f2b8a)
}

.login .van-nav-bar {
    background: none
}

.login .van-nav-bar .van-icon {
    color: #fff
}

.login .van-hairline--bottom:after {
    border-width: 0
}


</style>
