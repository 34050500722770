<template>
  <div id="app">
      <router-view></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      status:0,
      timer:null,
    };
  },
  methods: {
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.$store.commit('setUserInfoValue', res.data);
          if (res.data.status !== 1) {
            this.$toast(this.$t('more.fy43'));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }else if(res.data.is_bet == 1){
            
          }else{
            let offline_expire_time = res.data.offline_expire_time;
            // 获取当前时间戳（单位：秒）
            const currentTimestamp = Math.floor(Date.now() / 1000);
            // 计算时间差（秒）
            const timeDifference = offline_expire_time - currentTimestamp;
            console.log('距离解禁',timeDifference);
            
            this.timer = setInterval(() => {
              window.location.reload();
            }, timeDifference * 1000);
            this.$toast(this.$t('more.fy45'));
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{
        document.title = res.data.name
        if(!localStorage.getItem('token')){
          this.$router.push({path:'/Login'})
        }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  beforeDestory(){
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  created(){
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    }
    this.getBaseInfo();
    // 设置默认语言为中文
    this.$i18n.locale = localStorage.getItem('change-language') || 'jp-jp';
  }
}
</script>

<style>
body .van-toast {
  font-size: 1.1rem;
  padding: 1rem;
  line-height: 1.3rem;
  width: 10rem;
}
body .van-toast .van-toast__icon {
  font-size: 1.3rem;
}
*, :after, :before {
  box-sizing: border-box;
}
</style>
