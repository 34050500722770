<template>
	<van-tabbar v-if="show" v-model="active" active-color="#a06098" :border="true" inactive-color="#c8aed9">
		<van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
			<span>{{ k.title }}</span>
			<template #icon="props">
				<img width="22" height="22" :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title"  />
				<!-- <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" style="height: 4rem;" v-show="key === 2" /> -->
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Home',
					title: this.$t('common.homenav'),
					icon: {
						active: '/img/footer/home-active.png',
						noactive: '/img/footer/home.png'
					}
				},
				{
					router: '/Video',
					title: this.$t('video.title'),
					icon: {
						active: '/img/footer/foot2-active.png',
						noactive: '/img/footer/foot2.png'
					}
				},
				{
					router: '/Choose',
					title: this.$t('choose.nav'),
					icon: {
						active: '/img/footer/foot3-active.png',
						noactive: '/img/footer/foot3.png'
					}
				},
				// {
				// 	router: '/Pl',
				// 	title: this.$t('common.pinglunnav'),
				// 	icon: {
				// 		active: '/img/footer/foot4-active.png',
				// 		noactive: '/img/footer/foot4.png'
				// 	}
				// },
				{
					router: '/Mine',
					title: this.$t('common.minenav'),
					icon: {
						active: '/img/footer/mine-active.png',
						noactive: '/img/footer/mine.png'
					}
				}
			]
		};
	},
	methods: {},
	watch: {
		$route(to) {
			if (to.name == 'home') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'video') {
				this.active = 1;
				this.show = true;
			} else if (to.name == 'choose') {
				this.active = 2;
				this.show = true;
			} 
			// else if (to.name == 'pl') {
			// 	this.active = 3;
			// 	this.show = true;
			// } 
			else if (to.name == 'mine') {
				// this.active = 4;
				this.active = 3;
				this.show = true;
			} else {
				this.show = false;
			}
		}
	},
	created() {
		if (this.$route.name == 'home') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'video') {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == 'choose') {
			this.active = 2;
			this.show = true;
		} 
		// else if (this.$route.name == 'pl') {
		// 	this.active = 3;
		// 	this.show = true;
		// } 
		else if (this.$route.name == 'mine') {
			// this.active = 4;
			this.active = 3;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 3.5rem;
@tabbar-img: 1.4rem;
.van-tabbar {
	height: @tabbar-height;
}
@media (min-width: 500PX) {
	.van-tabbar {
		width: 500PX;
		left: 50%;
		transform: translateX(-50%);
	}
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
}
.van-tabbar-item {
	font-size: 1rem;
}
.tui {
	width: 4rem;
	margin-top: -10.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}
</style>
