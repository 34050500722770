export default {
	Language: '한국어',
	system: {
		homePage: {
			title1: '标题1',
			title2: '标题2',
			description1: '近7日',
			description2: '近6个月',
		}
	},
	login:{
		register:'회원가입',
		usernamePls:'아이디를 입력해주세요',
		passwordPls:'비밀번호를 입력해주세요',
		toRegister:'계정이 없나요? 지금 회원가입하세요',
		doLogin:'로그인',	  
	  },
	register:{
		repasswordPls:'출금 비밀번호를 입력하세요',  
		codePls:'초대코드를 입력해주세요',  
		register:'등록',
		xieyi:'이용약관 동의합니다',
		xieyiPls:'아래 계좌 개설 협의를 선택하세요!',
	},
	mine:{
		xyfs:'신용점수',
		ljjf:'누적 적립포인트',
		recharge:'포인트 충전',
		withdraw:'포인트 출금',
		money:'보유 포인트',
		infomation:'기본 정보',
		moneylog:'포인트 사용내역',
		withdrawrecord:'출금내역',
		gamerecord:'선물기록',
		notice:'공지사항',
		mymove:'시청기록',
		loginpwd:'로그인 비밀번호',
		paypwd:'비밀번호 사용',
		loginout:'로그아웃',
		paypwdhasseted:'현금 인출 비밀번호가 설정되었습니다. 수정이 필요하면 고객센터에 연락하십시오.',
		setbank:'수금 카드를 설정하세요!',
		lorr:'로그인 / 등록',
	},
	infomation:{
		username:'회원아이디',
		name:'성명',
		bankcard:'출금계좌',
		phone:'휴대폰번호',
		recharge:'충전',
		amount:'보유포인트',
		toserve:'고객 서비스에 문의하세요',
		kfms:'오프라인에서 충전을 원하시면 고객센터로 문의해주세요. 일부 결제채널의 충전금액은 소수점 1자리까지 랜덤으로 충전되오니 결제시꼭 확인해주세요',
	},
	withdraw:{
		title:'포인트 교환',
		log:'출금 기록',
		method:'출금방법',
		avmoney:'사용 가능포인트',
		limit:'출금한도',
		submit:'포인트 출금신청',
		xiane:'단일 한도액',
		numbers:'현금 인출 횟수',
		time:'입금 시간',
		zuidi:'최소',
		zuigao:'최고',
		numsmax:'하루 최대 현금 인출',
		ci:'횟수',
		timedetail:'보통 입금 시간은 5분 정도이고, 빠르면 2분 안에 입금됩니다.',
		bank:'은행 카드',
		status1:'심사 중',
		status2:'출금 완료',
		status3:'출금불가',
	},
	moneylog:{
		title:'포인트 세부정보',
		type1:'충전',
		type2:'채금',
		type3:'작업',
		type4:'보너스',
		type5:'출금완료',
		type6:'출금 실패',
	},
	setting:{
		loginpwdtitle:'로그인 비밀번호 변경',
		oldpwd:'이전 비밀번호를 입력하세요',
		newpwd:'새 비밀번호를 입력하세요',
		renewpwd:'새 비밀번호를 다시 입력하세요',
		change:'변경하기',
		plseall:'입력한 암호가 일치하지 않습니다!',
		pswwrong:'암호 입력이 두 번 일치하지 않음',
		paypwdtitle:'자금 암호 설정',
		paypwd:'출금계좌 비밀번호를 입력하세요',
		repaypwd:'비밀번호를 다시 한 번 확인해 주세요',
	},
	home:{
		gonggao:'궁극의 럭셔리 경험을 즐겨보세요',
		qgkf:'전국가능',
		hotlist:'인기 추천목록',
		hotgame:'창 활성화',
		gaoji:'고급',
		smrz:'실사인증',
		videorz:'본인영상인증',
		star:'별점',
		sg:'신장',
		xw:'사이즈',
		diqu:'서비스가능지역',
		apply:'데이트 신청하기',
		ssxz:'실시간 현황',
		joinvip:'님이 회원 가입하였습니다',
	},
	video:{
		title:'영상관',
		nums:'조희수',
		bofangliang:'조희수',
		hotlist:'인기 추천',
		plsrefisrt:'충전 후 동영상을 보십시오!',
	},
	choose:{
		title:'최고의 하루밤',
		nav:'프로필',
		apply:'매칭 신청',
		jianjie:'매니저 프로필',
		move:'이동가능여부',
		click:'인증하려면 클릭하세요',
		detail:'상세정보',
		videodetail:'라이브 비디오',
	},
	common:{
		refresh:'새로 고침 성공',
		noallow:'기능이 비활성화되었습니다!',
		disable:'비활성화',
		all:'전체',
		nomore:'더 이상 없어요.',
		nodata:'데이터 없음',
		submit:'제출',
		more:'매니저 더보기',
		pinglunnav:'이용후기',
		pingluntitle:'매니저 후기',
		pldtitle:'댓글 세부정보',
		minenav:'내 계정',
		homenav:'홈',
		addbank:'수금 카드 추가',
		setbank:'출금 계좌 등록',
	},
	game:{
		expect:'기수',
		jieguo:'데이터',
		da:'청순한',
		xiao:'섹시한',
		dan:'귀여운',
		shuang:'패티쉬',
		大:'청순한',
		小:'섹시한',
		单:'귀여운',
		双:'패티쉬',
		order:'작업 명세서',
		clear:'주문 비우기',
		submit:'제출 확인',
		kjcc:'당첨 성공, 기호:',
		lxgly:'이 작업은 관리자에게 문의하십시오.',
		plsmoney:'금액을 기입해 주세요!',
		plsnum:'번호를 선택하세요!',
		moneynote:'잔액이 부족하니 고객센터에 연락하여 충전하세요!',
		lefttime:'남은시간',
		lunci:'회차',
		switch:'스위치',
		fy1:'현재 선택',
		fy2:'수량',
		fy3:'총',
		fy4:'티켓 양',
		fy5:'사용 가능한 포인트',
		fy6:'인증하기',
		fy7:'체크 안됨',
	},
	kefu:{
		title:'온라인 고객센터',
		lianxi:'연락',
		content:'온종일 7 * 24시간 성심성의껏 서비스해드립니다',
	},
	setname:{
		title:'실명 수정',
		xingming:'이름',
		tips:'귀하의 계좌 안전을 위해 실제 이름은 바인딩 은행 카드 이름과 일치해야 합니다',
		xmpls:'실명을 입력하십시오',
		save:'저장',
		notreset:'설정을 반복하지 마십시오!',
		plsentername:'이름을 입력하십시오!',
	},
	bank: {
		nameRequired: '성함 설정 후 출금계좌 연동부탁드립니다！',
		payPasswordRequired: '현금인출 비밀번호를 설정한 후에 은행카드를 묶으세요!',
		bindCardTitle: '출금 계좌 등록',
		selectBankCard: '은행카드를 선택해주세요',
		accountNumber: '계좌번호',
		enterAccountNumber: '은행 카드 번호를 입력하세요',
		accountHolder: '예금주',
		enterRealName: '실명을 입력해주세요',
		withdrawalPassword: '출금 비밀번호',
		enterWithdrawalPassword: '출금 비밀번호 등록하실',
		addBankCard: '은행카드 추가',
		enterBankCardNumber: '은행 카드 번호를 입력하세요!',
		selectBank: '은행을 선택하세요!',
		bankCard: '은행 카드',
	},
	
	metaTitles:{
		title1: '로맨틱',
		title2: '로맨틱',
		title3: '로맨틱-프로필',
		title4: '매니저 후기',
		title5: '매니저 후기',
		title6: '프로필',
		title7: '프로필',
		title8: '로맨틱-나의',
		title9: '비디오',
		title10: '게임',
		title11: '로그인',
		title12: '등록',
		title13: '온라인 고객센터',
		title14: '온라인 고객센터',
		title15: '설치',
		title16: '프로파일',
		title17: '실명 수정',
		title18: '성별 수정',
		title19: '결제 비밀번호 관리',
		title20: '로그인 암호 관리',
		title21: '상세 정보 페이지',
		title22: '로맨틱-정보 공지',
		title23: '비디오 재생',
		title24: '수금 카드를 기입하다',
		title25: '수금 카드를 기입하다',
		title26: '현금 인출',
		title27: '충전',
		title28: '개인 보고서',
		title29: '현금 인출 기록',
		title30: '작업 레코드',
		title31: '작업 상세 정보',
		title32: '잔액 기록',
		title33: '로맨틱-내 영화'
	},
	more:{
		fy1:'가능',
		fy2:'엄지손가락',
		fy3:'보안방침',
		fy4:'저희 로맨틱은 회원님의 개인정보 보호를위해 본인인증서비스를 통해 인증완료 또는 인증이완료된 회원들의 추천을 통해서 가입이 가능합니다.',
		fy5:'작업 기록',
		fy6:'주문 내용',
		fy7:'일 {count} 마침표',
		fy8:'모든 작업',
		fy9:'초기화',
		fy10:'확신하는',
		fy11:'모두',
		fy12:'보너스 포인트',
		fy13:'결과 대기중',
		fy14:'미션금 내역',
		fy15:'미션결과',
		fy16:'점',
		fy17:'작업 포인트',
		fy18:'선물이 완료되었습니다.',
		fy19:'주문시간',
		fy20:'세부',
		fy21:'콘텐츠',
		fy22:'금액',
		fy23:'결과',
		fy24:'시간',
		fy25:'승리',
		fy26:'미션 실패',
		fy27:'시스템 공지',
		fy28:'내 영화',
		fy29:'더 많은 영화표를 받으려면 작업을 완료하세요',
		fy30:'영화 릴',
		fy31:'시청목록',
		fy32:'아직 데이터가 없습니다',
		fy33:'인출 비밀번호를 입력하다.',
		fy34:'암호',
		fy35:'"정확한 금액을 기입해 주십시오."',
		fy36:'현금인출 은행카드를 선택하세요.',
		fy37:'결제 비밀번호를 입력하십시오.',
		fy38:'알림',
		fy39:'기존 휴대전화번호',
		fy40:'새 휴대전화번호',
		fy41:'휴대폰번호 변경',
		fy42:'인증에 대해서는 관리자에게 문의하세요.',
		fy43:'계정 오프라인',
		fy44:'시스템 구성을 가져오지 못했습니다. 네트워크 연결을 확인하세요.',
		fy45:'현재 네트워크가 비정상적입니다. 네트워크를 변경해 주세요.',
		fy46:'아바타 변경',
	}
};
