export default {
	Language: '日本語',
	system: {
		homePage: {
			title1: 'タイトル1',
			title2: 'タイトル2',
			description1: '過去7日間',
			description2: '約6ヶ月',
		}
	},
	login:{
		register:'会員登録',
		usernamePls:'ユーザー名を入力してください',
		passwordPls:'パスワードを入力してください',
		toRegister:'アカウントはありませんか？「今から会員登録しよう」',
		doLogin:'ログイン',	  
	},
	register:{
		repasswordPls:'引き出しパスワードを入力してください',  
		codePls:'招待コードを入力してください',  
		register:'登録',
		xieyi:'利用規約に同意する',
		xieyiPls:'次の口座開設プロトコルを選択してください！',
	},
	mine:{
		xyfs:'信用スコア',
		ljjf:'積算積分',
		recharge:'チャージ',
		withdraw:'引き出し',
		money:'利用可能残高',
		infomation:'基本情報',
		moneylog:'積分記録',
		withdrawrecord:'引き出し明細',
		gamerecord:'贈り物履歴',
		notice:'案内事項',
		mymove:'視聴履歴',
		loginpwd:'ログインパスワード',
		paypwd:'パスワードの設定',
		loginout:'ログアウト',
		paypwdhasseted:'引き出しパスワードが設定されています。変更が必要な場合は、カスタマーセンターにお問い合わせください',
		setbank:'入金カードを設定してください！！',
		lorr:'ログイン/登録',
	},
	infomation:{
		username:'会員ID',
		name:'名前',
		bankcard:'引き出し口座',
		phone:'携帯電話番号',
		recharge:'チャージ',
		amount:'保有ポイント',
		toserve:'カスタマーサービスに連絡してください',
		kfms:'オフラインで充電したい場合は、カスタマーサービスにお問い合わせください。一部の決済チャネルのチャージ金額は小数点1桁にランダムにチャージされますので、決済時に必ず確認してください',
	},
	withdraw:{
		title:'引き出し',
		log:'出金記録',
		method:'引き出し方法',
		avmoney:'引き出し可能残高',
		limit:'引き出し限度',
		submit:'引き出し申請',
		xiane:'一筆書きの限度額',
		numbers:'引き出し回数',
		time:'入金時間',
		zuidi:'最小',
		zuigao:'最高',
		numsmax:'1日最大現金引き出し',
		ci:'次',
		timedetail:'一般的な送金時間は5分程度で、最速で2分以内に送金する。',
		bank:'銀行カード',
		status1:'レビュー待ち',
		status2:'成功',
		status3:'失敗',
	},
	moneylog:{
		title:'資金明細',
		type1:'変更',
		type2:'彩金',
		type3:'タスク',
		type4:'ボーナス',
		type5:'引き出し',
		type6:'引き出しに失敗した',
	},
	setting:{
		loginpwdtitle:'ログインパスワードの変更',
		oldpwd:'古いパスワードを入力してください',
		newpwd:'新しいパスワードを入力してください',
		renewpwd:'新しいパスワードを再入力してください',
		change:'変更',
		plseall:'完全な表に記入してください！',
		pswwrong:'2回のパスワード入力が一致しない',
		paypwdtitle:'資金パスワードの設定',
		paypwd:'資金パスワードを入力してください',
		repaypwd:'資金パスワードを再入力してください',
	},
	home:{
		gonggao:'究極のラグジュアリー体験を楽しむ',
		qgkf:'ナショナルポッシブル',
		hotlist:'人気おすすめリスト',
		hotgame:'タスクの推奨',
		gaoji:'高度な',
		smrz:'実地認証',
		videorz:'本人ビデオ認証',
		star:'スコア',
		sg:'身長',
		xw:'バスト',
		diqu:'サービス可能領域',
		apply:'デートを申し込む',
		ssxz:'リアルタイムの現状',
		joinvip:'会員に加入しました',
	},
	video:{
		title:'映像館',
		nums:'ビュー',
		bofangliang:'ビュー',
		hotlist:'人気推薦',
		plsrefisrt:'チャージしてビデオを見てください！！',
	},
	choose:{
		title:'最高の夜',
		nav:'セフレ選び',
		apply:'マッチング申請',
		jianjie:'美女プロフィール',
		move:'移動可能かどうか',
		click:'クリック認証',
		detail:'詳細',
		videodetail:'リアルタイムビデオ',
	},
	common:{
		refresh:'リフレッシュ成功',
		noallow:'機能が無効になっています！！',
		disable:'無効',
		all:'すべて',
		nomore:'これ以上はありません。',
		nodata:'データなし',
		submit:'コミット',
		more:'詳細を表示',
		pinglunnav:'コメント',
		pingluntitle:'コメント',
		pldtitle:'コメント詳細',
		minenav:'アカウント',
		homenav:'ホームページ',
		addbank:'入金カードの追加',
		setbank:'引き出し口座',
	},
	game:{
		expect:'期間番号',
		jieguo:'データ',
		da:'無実の',
		xiao:'セクシー',
		dan:'かわいい',
		shuang:'フェティッシュ',
		大:'無実の',
		小:'セクシー',
		单:'かわいい',
		双:'フェティッシュ',
		order:'タスクシート',
		clear:'注文を空にする',
		submit:'確定提出',
		kjcc:'受賞成功、期号:',
		lxgly:'管理者に連絡してください.',
		plsmoney:'金額を記入してください！',
		plsnum:'番号を選択してください！',
		moneynote:'残高が足りないので、カスタマーサービスに連絡してチャージしてください！',
		lefttime:'残り時間',
		lunci:'期号',
		switch:'切り替え',
		fy1:'現在の選択',
		fy2:'数量',
		fy3:'投票数',
		fy4:'合計',
		fy5:'利用可能なポイント',
		fy6:'認証を行う',
		fy7:'未選択',
	},
	kefu:{
		title:'オンラインカスタマーサービス',
		lianxi:'連絡',
		content:'24時間365日対応',
	},
	setname:{
		title:'実名修正',
		xingming:'名前',
		tips:'アカウントのセキュリティのために、実際の名前はバインドされた銀行カード名と一致しなければなりません',
		xmpls:'実際の名前を入力してください',
		save:'保存',
		notreset:'繰り返し設定しないで！',
		plsentername:'名前を入力してください！',
	},
	bank: {
		nameRequired: '先に名前を設定してから、銀行カードを登録してください！',
		payPasswordRequired: '先に引き出しパスワードを設定してから、銀行カードを登録してください！',
		bindCardTitle: '引き出し口座登録',
		selectBankCard: '銀行カードを選択してください',
		accountNumber: '口座番号',
		enterAccountNumber: '銀行口座番号を入力してください',
		accountHolder: '口座名義',
		enterRealName: '本名を入力してください',
		withdrawalPassword: '引き出しパスワード',
		enterWithdrawalPassword: '引き出しパスワードを入力してください',
		addBankCard: '銀行カードを追加',
		enterBankCardNumber: '銀行カード番号を入力してください！',
		selectBank: '銀行を選択してください！',
		bankCard: '銀行カード',
	},
	metaTitles:{
		title1: "ロマンス",
		title2: "ロマンス",
		title3: "ロマンス-プロフィール",
		title4: "ブローカーフィードバック",
		title5: "ブローカーフィードバック",
		title6: "プロフィール",
		title7: "プロフィール",
		title8: "ロマンス-マイページ",
		title9: "ビデオ",
		title10: "ゲーム",
		title11: "ログイン",
		title12: "ログイン/登録",
		title13: "オンラインカスタマーサービス",
		title14: "オンラインカスタマーサービス",
		title15: "インストール",
		title16: "プロフィール",
		title17: "実名変更",
		title18: "性別変更",
		title19: "支払いパスワード管理",
		title20: "ログインパスワード管理",
		title21: "詳細情報ページ",
		title22: "ロマンス-お知らせ",
		title23: "ビデオ再生",
		title24: "受取カードの入力",
		title25: "受取カードの入力",
		title26: "現金引き出し",
		title27: "チャージ",
		title28: "個人レポート",
		title29: "現金引き出し記録",
		title30: "作業記録",
		title31: "操作詳細",
		title32: "残高記録",
		title33: "ロマンス-マイムービー"
	},
	more: {
		fy1: "利用可能",
		fy2: "親指",
		fy3: "セキュリティポリシー",
		fy4: "当社は会員の個人情報を保護するため、本人認証サービスによる認証、または認証済み会員の推薦による登録を行っています。",
		fy5: "操作記録",
		fy6: "注文内容",
		fy7: "{count} 番目の記録",
		fy8: "すべての操作",
		fy9: "リセット",
		fy10: "確認",
		fy11: "すべて",
		fy12: "報酬ポイント",
		fy13: "結果待ち",
		fy14: "タスク金記録",
		fy15: "タスク結果",
		fy16: "ポイント",
		fy17: "タスクポイント",
		fy18: "ギフトが完了しました。",
		fy19: "注文時間",
		fy20: "詳細",
		fy21: "内容",
		fy22: "金額",
		fy23: "結果",
		fy24: "時間",
		fy25: "勝利",
		fy26: "タスク失敗",
		fy27: "システム通知",
		fy28: "私の映画",
		fy29: "タスクを完了して映画チケットを獲得",
		fy30: "映画カルーセル",
		fy31: "ウォッチリスト",
		fy32: "データなし",
		fy33: "引き出しパスワードを入力してください。",
		fy34: "パスワード",
		fy35: "正確な金額を入力してください。",
		fy36: "現金引き出し用の銀行カードを選択してください。",
		fy37: "支払いパスワードを入力してください。",
		fy38: "通知",
		fy39: "現在の電話番号",
		fy40: "新しい電話番号",
		fy41: "電話番号の変更",
		fy42: "認証について、管理者にご連絡ください。",
		fy43: "アカウントがオフライン",
		fy44:'システム構成を取得できませんでした。ネットワーク接続を確認してください',
		fy45:'現在のネットワークは異常です。ネットワークを変更してください',
		fy46:'アバターを変更',
	}
	  
  
};
