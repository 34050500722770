<template>
    <div class="beauty">
        <van-nav-bar :title="$t('choose.nav')" left-text=""  fixed>
            <template #right>
                <van-icon v-if="typeshow==2" name="/img/girl1.png" size="20" @click="typeChange(1)" />
                <van-icon v-else-if="typeshow==1" name="/img/girl2.png" size="22" @click="typeChange(2)"/>
            </template>
        </van-nav-bar>
        <div class="type">
            <div class="top">
                <img v-if="currentLanguage == 'jp-jp'" src="/img/jp.gif" alt="" style="width: 100%;">
                <img v-else src="/img/ko.gif" alt="" style="width: 100%;">
            </div>
            <div class="nav_tab" v-if="userInfo.is_bet == 1">
                <a @click="changeAddress('all')" :class="'all'==address_id?'active':''" >{{$t('common.all')}}</a>
                <a @click="changeAddress(val.id)" :class="val.id==address_id?'active':''"  v-for="(val, key) in addlist" :key="key">{{val.name}}</a>
            </div>
            <template v-if="userInfo.is_bet == 1">
                <div class="type1" v-if="typeshow==1" @scroll="handleScroll">
                    <div role="feed" class="van-list" aria-busy="false">
                        <div>
                            <ul class="list">
                                <li  v-for="(v, key) in osList" :key="key">
                                    <div class="dd">
                                        <span>
                                            <i>
                                                {{$t('home.gaoji')}}
                                            </i>
                                        </span>
                                        <img :src="v.image" :alt="v.id" @click="profile(v.id)">
                                        <div class="fot">
                                            <div>
                                                <i class="van-badge__wrapper van-icon van-icon-location" style="font-size: 13px;"></i>
                                                {{v.class_name}}
                                            </div>
                                            <a  @click="profile(v.id)">
                                                {{$t('home.apply')}}
                                            </a>
                                        </div>
                                    </div>
                                    <p>
                                        {{v.tags_arr && v.tags_arr.length > 0 ? v.tags_arr[0] : ''}}
                                    </p>
                                    <div class="di">
                                        <p>
                                            <i></i>
                                            <span>
                                                {{v.xuanfei_name}}
                                            </span>
                                        </p>
                                        <a>
                                            <i class="van-badge__wrapper van-icon van-icon-like" style="color: rgb(220, 99, 161); font-size: 14px;"></i>
                                            {{v.renqi}}
                                        </a>
                                    </div>
                                </li>
                                
                            </ul>
                            <ul class="list">
                                <li  v-for="(v, key) in jsList" :key="key" @click="profile(v.id)">
                                    <div class="dd">
                                        <span>
                                            <i>
                                                {{$t('home.gaoji')}}
                                            </i>
                                        </span>
                                        <img :src="v.image" :alt="v.id" @click="profile(v.id)">
                                        <div class="fot">
                                            <div>
                                                <i class="van-badge__wrapper van-icon van-icon-location"></i>
                                                {{v.class_name}}
                                            </div>
                                            <a @click="profile(v.id)">
                                                {{$t('home.apply')}}
                                            </a>
                                        </div>
                                    </div>
                                    <p>
                                        {{v.tags_arr && v.tags_arr.length > 0 ? v.tags_arr[0] : ''}}
                                    </p>
                                    <div class="di">
                                        <p>
                                            <i></i>
                                            <span>
                                                {{v.xuanfei_name}}
                                            </span>
                                        </p>
                                        <a>
                                            <i class="van-badge__wrapper van-icon van-icon-like"
                                            style="color: rgb(220, 99, 161); font-size: 14px;">
                                                
                                                
                                                </i>
                                            {{v.renqi}}
                                        </a>
                                    </div>
                                </li>
                                
                            </ul>
                        </div>				
                        <div class="van-list__placeholder"></div>
                    </div>
                    
                </div>
                <div class="type2" v-else  @scroll="handleScroll">
                    <div role="feed" class="van-list" aria-busy="false">
                        <ul class="list">
                            <li class="btn" v-for="(v,key) in tableList" :key="key"  @click="profile(v.id)" >
                                <div class="left">
                                    <img :src="v.image" alt="">
                                    <span>
                                        <i>
                                            {{$t('home.gaoji')}}
                                        </i>
                                    </span>
                                </div>
                                <div class="right">
                                    <div class="title">
                                        <span>
                                            <i class="van-badge__wrapper van-icon" style="font-size: 13px;">
                                                
                                                <img class="van-icon__image" src="/img/vipu.png">
                                                </i>
                                            {{$t('home.smrz')}}
                                        </span>
                                        <span>
                                            <i class="van-badge__wrapper van-icon" style="font-size: 13px;">
                                                
                                                <img class="van-icon__image" src="/img/vipu.png">
                                                </i>
                                            {{$t('home.videorz')}}
                                        </span>
                                        <p>
                                            {{v.class_name}}
                                        </p>
                                    </div>
                                    <div class="tips">
                                        <span v-for="(val,index) in (v.tags_arr || [])" :key="index"  >
                                            {{val}}
                                        </span>
                                    </div>
                                    <div class="sm">
                                        <p class="sf">
                                            {{$t('home.star')}}：
                                            <span v-for="(numa,indexs) in v.star" :key="indexs" ></span>
                                            </p>
                                        <div>
                                            {{$t('home.sg')}}: {{v.sg}}cm {{$t('home.xw')}}：{{v.sw}}{{$t('home.diqu')}}：{{v.class_name}}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            </ul>
                        
                        
                        
                        <div class="van-list__placeholder"></div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="typeshow==1" style="display: flex;flex-wrap: wrap;padding: 10px;overflow: auto;height: calc(100vh - 20rem)">
                    <div style="margin-bottom: 10px;width: 45%;margin: 0 auto 10px;" v-for="item in 6" :key="item">
                        <div style="height: 200px;background-color: #f6f7f8;margin-bottom: 10px;"></div>
                        <van-skeleton  :row="2" />
                    </div>
                </div>
                <div v-else style="overflow: auto;height: calc(100vh - 20rem);" >
                    <van-skeleton avatar-size="120px" style="padding: 20px 15px;" v-for="item in 6" :key="item" avatar-shape="square" avatar :row="4" ></van-skeleton>
                </div>
            </template>
        </div>
        
        <div class="backtop">
            <i class="van-badge__wrapper van-icon" style="font-size: 50px;">
                
                <img class="van-icon__image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LjljY2M0ZGU5MywgMjAyMi8wMy8xNC0xNDowNzoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjMgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkQ0REM5QzUyMzFCQzExRURBQUYxRTVDODE1NzRFMjdDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkQ0REM5QzUzMzFCQzExRURBQUYxRTVDODE1NzRFMjdDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RDREQzlDNTAzMUJDMTFFREFBRjFFNUM4MTU3NEUyN0MiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RDREQzlDNTEzMUJDMTFFREFBRjFFNUM4MTU3NEUyN0MiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6K5i40AAAB+UlEQVR42uyYO0sDQRCAs8E/IAo2ihcLLURj7fNSio1aKIpggkJQiK8/YPIHjGLhA/USLQRFbATLXND0xkchgtk0gmijf+CcCbtxE06CUYnGGdjM7s4s3HczO3OEWZblKAdxOspECIRACIRACIRACIRACIRACIRACIRACIRAflQq1EW4/1UDtchwwSwHaoY6d56ePqwKosvm8JPuZNa4tDuFr6JDo7u1HH0PfOkgrOttfFDH+9YbI98GAoIget5aChfaxJ+NoWeNsYzdq9jyz0UV27iyz/N9T6duzd61Jl4sCPvoD7rlgRcd3nJMRMEMHFV6VDuAoE0Xb9Q1sV+TfYi9sQcv7BnCFhmJ1vkgIilYa7g3uO1i0vfEf2fJyAAI+zd35Gzu0jifT+qFUuvXSnz2SoNwYTpjKqcT80neEXbzvxiRRgxm3npInI8eW+8V61sAYl+5G/OXBsQCZlOPlk4EgsXBhyPti+1maWKiKaMDARcdNvyGwvcyMqIEulabkW/uKh8Xln1ShKRga0GTxHHQt0rLRnYzrA7CHcEAVLfkVoh8WYwX42dkUelyWX7hU/2nS+Kx6ZX5ewXApEPEc83+A+qTejsOO2xOcfFMKGHqI1U+zTBajO3gxAVixdsiPTRSCAEQiAEQiAEQiAEQiAEQiA/LG8CDABdyKZWjfllBgAAAABJRU5ErkJggg==">
                </i>
        </div>
    </div>
    </template>
    
    <script>
    import { Toast } from 'vant';
    import { mapState } from 'vuex';
    export default {
        data() {
            return {
                addlist: [],
                address_id:'all',
                typeshow:1,
                page:1,
                isLoading: false,
                count:0,
                limit:10,
                loading: false,
                finished: false,
                refreshing: false,
                tableList:[],
            };
        },
        mounted () {
          this.typeshow = localStorage.getItem('typeshow') || 1;
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo
            }),
            osList() {
              return this.tableList.filter((item, index) => index % 2 === 0);
            },
            jsList() {
            return this.tableList.filter((item, index) => index % 2 === 1);
            },
              
            currentLanguage() {
                return this.$i18n.locale;
            }
          },
        methods: {
            typeChange(type){
                this.typeshow = type;
                localStorage.setItem('typeshow',type);
            },
            handleScroll(e) {
                const { scrollTop, scrollHeight, clientHeight } = e.target;
                if (scrollTop + clientHeight >= scrollHeight - 5 && this.tableList.length < this.count) { // 5是一个缓冲值，避免提前加载
                    
                    if(this.loading==false){
                        this.getPinglunList();
                    }
                    
                    
                }
            },
            profile(id) {
                this.$router.push({ path: '/profile?id=' + id  });
            },
            addgo(data) {
                if (!localStorage.getItem('token')) {
                    this.$router.push({ path: '/Login' });
                } else {
                    this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
                }
            },
            changeAddress(addressId){
                this.address_id = addressId;
                this.page = 1;
                this.tableList=[];
                this.getPinglunList();
            },
            getPinglunList() {
                this.loading = true;
                this.$http({
                    method: 'get',
                    url: 'xuanfeilist',
                    data: { id: this.address_id,page: this.page,limit: this.limit }
                }).then(res => {
                    console.log('API响应数据:', res);
                    // 处理不同格式的API响应
                    if (res.code === 200) {
                        let validData = [];
                        
                        // 处理第一种格式：data.data数组
                        if (res.data && Array.isArray(res.data.data)) {
                            validData = res.data.data.filter(item => item && (item.image || item.img_url));
                            // 将img_url映射到image字段
                            validData = validData.map(item => {
                                if (!item.image && item.img_url) {
                                    item.image = item.img_url;
                                }
                                // 确保tags_arr是数组
                                if (!item.tags_arr) {
                                    item.tags_arr = item.tags ? item.tags.split(',') : [];
                                }
                                // 确保其他必要字段
                                if (!item.class_name) item.class_name = item.xuanfei_name || '未知';
                                if (!item.renqi) item.renqi = item.sort || 0;
                                return item;
                            });
                            this.count = res.data.total || 0;
                        } 
                        // 处理第二种格式：data.list数组
                        else if (res.data && Array.isArray(res.data.list)) {
                            validData = res.data.list.filter(item => item && (item.image || item.img_url));
                            // 将img_url映射到image字段
                            validData = validData.map(item => {
                                if (!item.image && item.img_url) {
                                    item.image = item.img_url;
                                }
                                // 确保tags_arr是数组
                                if (!item.tags_arr) {
                                    item.tags_arr = item.tags ? item.tags.split(',') : [];
                                }
                                // 确保其他必要字段
                                if (!item.class_name) item.class_name = item.xuanfei_name || '未知';
                                if (!item.renqi) item.renqi = item.sort || 0;
                                return item;
                            });
                            this.count = res.data.total || 0;
                        }
                        // 处理第三种格式：data本身是数组
                        else if (Array.isArray(res.data)) {
                            validData = res.data.filter(item => item && (item.image || item.img_url));
                            // 将img_url映射到image字段
                            validData = validData.map(item => {
                                if (!item.image && item.img_url) {
                                    item.image = item.img_url;
                                }
                                // 确保tags_arr是数组
                                if (!item.tags_arr) {
                                    item.tags_arr = item.tags ? item.tags.split(',') : [];
                                }
                                // 确保其他必要字段
                                if (!item.class_name) item.class_name = item.xuanfei_name || '未知';
                                if (!item.renqi) item.renqi = item.sort || 0;
                                return item;
                            });
                            this.count = validData.length;
                        }
                        
                        console.log('处理后的数据:', validData);
                        this.tableList = this.tableList.concat(validData);
                        this.page++;
                    } else {
                        console.error('API返回错误:', res.msg || '未知错误');
                        Toast(res.msg || '获取数据失败');
                    }
                    this.loading = false;
                }).catch(error => {
                    console.error('获取列表失败:', error);
                    Toast('获取列表失败，请检查网络连接');
                    this.loading = false;
                });
            },
            getAddress() {
                this.$http({
                    method: 'get',
                    url: 'address_list'
                }).then(res => {
                    console.log(res.data)
                    this.addlist = res.data.flat();
                });
            },
            onRefresh(){
              setTimeout(() => {
                this.finished = false;
                this.loading = true;
                this.onLoad();
                this.isLoading = false;
                Toast(this.$t('common.refresh'));
              }, 500);
            },
            onLoad() {
              //初始化列表
              // this.getPinglunList()
              let timer = setTimeout(() => {
                if (this.refreshing) {
                  this.tableList = [];
                  this.refreshing = false;
                }
                this.loading = false;
                if (this.tableList.length === this.count) {
                  this.finished = true;
                }
                this.finished && clearTimeout(timer);//清除计时器
              }, 500);
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    if (res.code === 200) {
                    this.$store.commit('setUserInfoValue', res.data);
                        if (this.userInfo.status !== 1) {
                            this.$toast(this.$t('more.fy43'));
                            localStorage.clear()
                            this.$router.push({ path: '/Login' })
                        }else if(this.userInfo.is_bet == 1){
                            this.getAddress();
                            this.getPinglunList()
                        }else{
                            this.$toast(this.$t('more.fy45'));
                        }
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                        this.getBasicConfig();
                    }
                })
            },
        },
        created() {
            if (localStorage.getItem('token')) {
                this.getUserInfo();
            }else{
                this.$router.push({path:'/Login'})
            }
        }
    };
    </script>
    
    <style lang="less" scoped>
    :export {
        gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
    }
    .type {
      position: relative;
      overflow-y: auto;
    }
    .type1,.type2{
        height: calc(100vh - 20rem);
        overflow-y: auto;
    }
    
    .van-nav-bar {
        position: relative;
        z-index: 999;
        height: 3rem;
        line-height: 3rem;
        background: url(/img/header.d7ab133e.svg) no-repeat 50%/100%;
    }
    
    .van-nav-bar__content{
        height:3rem;
    }
    
    .van-list>div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 96%;
        margin: 0 auto;
        position: relative
    }
    
    .type1 .list {
        width: calc(50% - .66667rem);
        margin: 0 auto;
        border-top: 1px solid #eee;
        margin-top: .66667rem;
        padding-top: .66667rem
    }
    
    .list li {
        width: 100%;
        padding-bottom: .66667rem
    }
    
    
    .list li>p {
        text-align: left;
        color: #666;
        font-size: .76667rem;
        padding-top: .2rem
    }
    
    .list li .di {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
        font-size: .76667rem;
        padding: .2rem 0
    }
    
    .list li .di p {
        display: flex;
        justify-content: flex-start;
        align-items: center
    }
    
    .list li .di p i {
        display: inline-block;
        margin-right: .2rem;
        width: .8rem;
        height: .8rem;
        border-radius: 50%;
        background: #efba8f
    }
    
    .list li .dd {
        border-radius: .33333rem;
        overflow: hidden;
        position: relative
    }
    
    .list li .dd span {
        position: absolute;
        z-index: 1;
        top: -5.33333rem;
        right: -8.66667rem;
        width: 10rem;
        height: 10rem;
        background: #ebcaaf;
        transform: rotate(45deg);
        opacity: .8
    }
    
    .list li .dd span i {
        position: absolute;
        bottom: 0;
        left: 1.83333rem;
        color: #8d684b;
        font-weight: 600
    }
    
    .list li .dd .fot {
        position: absolute;
        bottom: 0;
        background: rgba(0,0,0,.4);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: .33333rem 5%;
        font-size: .76667rem
    }
    
    .list li .dd .fot a {
        border: .5PX solid #fff;
        padding: .06667rem .33333rem;
        border-radius: .13333rem;
        opacity: .9;
        font-size: 12PX
    }
    
    .list li .dd img,.nav_tab {
        width: 100%
    }
    
    .nav_tab {
        display: flex;
        flex-wrap: wrap
    }
    
    .nav_tab a {
        font-size: .76667rem;
        padding: .2rem .66667rem;
        // width: 14.28571428571429%; 
        color: #646566;
        transition: all .2s;
        cursor: pointer;
        margin-bottom: .33333rem
    }
    
    .nav_tab a.active {
        color: #333;
        background: #e2e2e2;
        transform: scale(1.1);
        border-bottom: .06667rem solid #b979b1
    }
    
    .xf .van-tabs__wrap {
        display: none;
        height: 26px
    }
    
    .xf .van-tabs__wrap .van-tab {
        padding: 0
    }
    
    .xf .van-tabs__wrap .van-tab span {
        padding: 3px 14px;
        transition: .2s;
        transform: scale(.9)
    }
    
    .xf .van-tabs__wrap .van-tab--active span {
        background: #e2e2e2;
        transform: scale(1)
    }
    
    :export {
        gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
    }
    .type2 .list {
        margin: 0 auto;
        border-top: 1px solid #eee;
        margin-top: .66667rem;
        padding: .66667rem
    }
    
    .type2 .list li {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #eee;
        margin-bottom: .66667rem;
        margin-top: 1px;
        padding-bottom: 0;
    }
    
    .type2 .list li .right {
        flex: 1;
        padding-left: .66667rem
    }
    
    .list li .right .btn {
        background: linear-gradient(90deg,#df35ad,#4f1db5);
        color: #fff;
        border-radius: .26667rem;
        padding: .33333rem 0;
        width: 90%;
        margin: .33333rem 0 .66667rem
    }
    
    .list li .right .title,.list li .right .title span {
        display: flex;
        justify-content: flex-start;
        align-items: center
    }
    
    .list li .right .title span {
        background: linear-gradient(180deg,#e7caaf,#fff7ed);
        color: #a4826b;
        font-weight: 600;
        margin-left: .2rem;
        padding: .16rem .4rem;
        font-size: .76667rem;
        border-radius: .46667rem 0 .46667rem 0
    }
    
    .list li .right .title p {
        padding-left: .66667rem;
        width: 40%;
        color: rgba(0,0,0,.85);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    
    .list li .right .sm {
        text-align: left;
        color: #666;
        font-size: .8rem
    }
    
    .list li .right .sm>div {
        color: #999;
        font-size: .8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 13.33333rem
    }
    
    .list li .right .sf {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: .33333rem
    }
    
    .list li .right .sf i,.list li .right .sf span {
        width: 1rem;
        height: 1rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVRIie3WMUhVURzH8c97WQg6VJIICeLUENIgDa+GmnSQCCKIHINqCkRoKWiwRVtcogjMzcVJHWrt4RBOWWu4iLW0NIRD8cjh3IeHy70+37vvbv7gDPd//ud+/3/O+f/Pqfz5eE8Heoo1fG93YbUD2CXM4XUHa9sGVvAOvZjA3bKBD3Aj+l5Ef1nAQbxK2YbxoizgIs5n2GdwudvASUznzJ0WDlClG8AqxvCmhd9NPMKpVsBKqg77cBXXUcM1nGv1k0i/sYlPqGMbjdihB7eTCGsYT2yd6ixuJSMOoJ4Esd2DAWHjy1AcwC/UqljGQknApvYT6E7z0DzHRkmwBu5ji8NT2jR+KwH4RJRMXBb7mMLPLsLm8TY2pOtwD3cSeFGt4FnamFX4W0IRF9Gm0Oj/HwcIXwoCP+Bv1kQecKggMKvJHwm8UBCY2w5PMszSrnAZH9Uk2gamM2xgXWgMo5jFFeGWWcW/lP9AHjDvKmpmuIslvJfdgerJuIjHeJisbXsPPzvM5mUOLNYP4TE1IjxFvuJMluMB65BGG6uhkFoAAAAASUVORK5CYII=) no-repeat 50%/100%
    }
    
    .list li .right .sf i {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABd0lEQVRIic3VX0tUQRjH8Y/LhoneRCKZWkQlIZKKN4qguG+i1+ilEAkSdFUhghdhN10sQimLCgmuiCCsFzsHj6dzlt3zB/rBwJln5nm+88yZZ2aovfNBDi2jib+DOtZywJ5gFZs5fHMBG6jjBWarBs5hJtZfx6OqgCMBENcYVqoCbuBxin0JT8sGvsS7HjEaZQGHMN5HwCm8D/N7qp7Sf4bnmAwtbRvT1MAaTvAHv3GGThLwGtMh+IR8pRJpGK9Cg5vkAuohg6UCkH4XcI2tGn5ivyJgpFts4yLavm+6d2MV6uATWtz/r8h4VgHwi1gy8QMSpd0uEbaPH3FD8kS28THAi+oXviaNaSXQwueCsBZ20wayau60ILApY5eygKMFgcNZA1nAfq+zLGX6/zcZjhQElprhJQ5wngeYfJ4iJTPs6J68Qxy5f3KmsIA3Hi5+YGCU4WWAHOIqZd5xaKO6D/B8+B4Y2MKeh9n00hW+B5+3WAyx/6nFOy/YRbaXhyhbAAAAAElFTkSuQmCC) no-repeat 50%/100%
    }
    
    .list li .right .tips {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        padding: .33333rem 0
    }
    
    .list li .right .tips span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .2rem .4rem;
        margin: .16667rem .16667rem;
        color: #fff;
        border-radius: .33333rem;
        font-size: .76667rem
    }
    
    .list li .right .tips span:nth-child(1n) {
        background: #fe257f
    }
    
    .list li .right .tips span:nth-child(2n) {
        background: #ff9702
    }
    
    .list li .right .tips span:nth-child(3n) {
        background: #0fa7fe
    }
    
    .list li .left {
        width: 7.26667rem;
        height: 7.26667rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: .33333rem;
        position: relative
    }
    
    .list li .left span {
        position: absolute;
        transform: rotate(45deg);
        right: -5.66667rem;
        width: 6.66667rem;
        height: 6.66667rem;
        top: -3.33333rem;
        background: #ebcaaf;
        color: #8d684b;
        opacity: .9
    }
    
    .list li .left span i {
        position: absolute;
        font-weight: 600;
        left: .8rem;
        bottom: 0;
        font-size: .8rem
    }
    
    .list li .left img {
        width: 100%
    }
    
    :export {
        gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
    }
    
    .backtop {
        position: fixed;
        right: .66667rem;
        bottom: 6.66667rem;
        z-index: 999;
        display: none
    }
    
    .backtop i {
        background: rgba(0,0,0,.23);
        border-radius: 50%;
        padding: 0
    }
    
    .type2 {
        width: 100%;
        background: #f3f2f7
    }
    
    .beauty {
        width: 100%;
        background: #fff;
        padding-bottom: 3.33333rem
    }
    
    .beauty .van-nav-bar__right i {
        padding-left: 10px
    }
    
    .beauty .notice-swipe {
        height: 1.33333rem
    }
    
    .beauty .selected {
        background: hsla(0,0%,100%,.8)
    }
    
    .beauty .selected .van-icon {
        font-size: 20px;
        color: #62519a
    }
    
    .beauty .selected .content {
        width: 94%;
        margin: 0 auto;
        text-align: left
    }
    
    .beauty .selected .content h1 {
        color: #f94e86;
        font-size: .93333rem
    }
    
    .beauty .selected .content ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 1rem
    }
    
    .beauty .selected .content ul:after {
        content: "";
        width: 30%;
        height: 0
    }
    
    .beauty .selected .content ul li {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3.33333rem;
        background: #fff;
        padding: .66667rem .66667rem;
        margin-bottom: .53333rem;
        color: #523e90
    }
    
    .beauty .selected>p {
        padding: 1rem 0 .66667rem;
        color: #62519a;
        font-size: 1.06667rem
    }
    
    .beauty .xuanfei {
        width: 94%;
        margin: 0 auto;
        padding: 1rem 0
    }
    
    .beauty .xuanfei .city {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-right: 70PX
    }
    
    .beauty .xuanfei .city li {
        margin: .2rem .13333rem;
        background: #f2f2f5;
        color: #4a2a90;
        padding: .33333rem .66667rem;
        font-size: 12PX;
        border-radius: .26667rem
    }
    
    .beauty .xuanfei .city li:last-child {
        position: absolute;
        right: .66667rem
    }
    
    .beauty .xuanfei .city li.active {
        color: #fff;
        background: linear-gradient(30deg,#8e4ff1,#c571cf)
    }
    
    .beauty .xuanfei .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .66667rem 0
    }
    
    .beauty .xuanfei .title p {
        color: #280064;
        font-size: .93333rem;
        font-weight: 600
    }
    
    .beauty .xuanfei .title .van-dropdown-menu {
        width: 80px;
        border-radius: 4px;
        overflow: hidden
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-item .van-popup {
        width: 80px;
        right: 10px;
        left: auto;
        border-radius: 0 0 2px 2px;
        overflow: hidden;
        background: none
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-item .van-popup .van-cell {
        padding: 10px 0;
        background: rgba(183,138,251,.62)
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-item .van-popup .van-cell .van-cell__title {
        width: 100%;
        font-size: 12px;
        padding: 0 10px;
        color: #fff
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-item .van-popup .van-cell .van-cell__value {
        position: absolute;
        right: 0
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-item .van-popup .van-cell .van-cell__value i {
        color: #e8238d
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-menu__bar {
        height: 26px;
        background: #b78afb;
        color: #fff
    }
    
    .beauty .xuanfei .title .van-dropdown-menu .van-dropdown-menu__bar .van-dropdown-menu__title {
        color: #fff;
        font-size: 12px
    }
    
    .beauty .top {
        width: 96%;
        margin: 0 auto
    }
    
    .beauty .top img {
        width: 100%;
        height: 5.33333rem;
        border-radius: .66667rem;
        margin: .33333rem 0
    }
    
    .beauty .van-notice-bar {
        width: 94%;
        margin: 10px auto;
        border-radius: 4px;
        height: 50px;
        padding-left: 5px
    }
    
    .beauty .van-notice-bar .van-notice-bar__left-icon {
        font-size: 80px
    }
    
    .beauty .van-notice-bar>.van-badge__wrapper {
        position: relative;
        padding-right: 14px
    }
    
    .beauty .van-notice-bar>.van-badge__wrapper:after {
        content: "";
        position: absolute;
        right: 7px;
        width: 3PX;
        border-radius: 6px;
        height: 50%;
        background: #d5d7df;
        top: 0;
        bottom: 0;
        margin: auto
    }
    
    .beauty .van-notice-bar__wrap {
        padding: 0 10px;
        font-size: 13px
    }
    
    .beauty .type1 .sm {
        width: 90%;
        margin: .66667rem auto;
        padding: .66667rem;
        background: #7e5678;
        color: #fff;
        border-radius: .33333rem;
        font-size: .96667rem
    }
    
    .beauty .girl {
        width: 100%;
        padding: 5% 5% 4rem
    }
    
    .beauty .girl p {
        font-size: 1rem;
        padding: .66667rem
    }
    
    .beauty .girl img {
        width: 100%
    }
    
    .beauty .city {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap
    }
    
    .beauty .city span {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .66667rem;
        font-size: 1rem;
        font-weight: 600
    }
    
    .beauty .city span.ds {
        color: #7e5678;
        text-decoration: underline
    }
    
    .beauty .cs>div {
        padding: 5% 2%;
        text-align: left
    }
    
    .beauty .cs>div span {
        font-size: 1rem;
        font-weight: 600;
        color: red;
        padding: .66667rem 0;
        display: inline-block
    }
    
    .beauty .cs>div p {
        font-size: 1rem
    }
    
    </style>
    