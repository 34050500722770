import axios from 'axios'
import api from './api'
import Vue from 'vue';
let Base64 = require('js-base64').Base64;
import { Toast } from 'vant';
// import qs from "qs";

Vue.use(Toast);
//创建axios实例对象 
const  instance = axios.create({
    // baseURL: 'https://admxo.leyou-1.com/api', //服务器地址
    // baseURL: 'http://www.kongjiang.com/api', //服务器地址
    // baseURL: 'http://118.99.57.131:88/api', //服务器地址
    baseURL: process.env.NODE_ENV=="development"?'https://adminkj.xianfenkeji.com/api' : '/api', //服务器地址
    // baseURL: '/api', //服务器地址
    // baseURL: process.env.NODE_ENV=="development"?'/api' : 'https://api.xianfenkeji.com/api', //服务器地址
    timeout: 5000, //默认超时时长
})


//请求拦截器
instance.interceptors.request.use(config=>{
    if(config.method === "post"){
        config.headers = {
            // 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8', // 设置跨域头部
            'content-type': 'application/json;charset=UTF-8', // 设置跨域头部
        }
    }
    if(localStorage.getItem('token')){
        config.headers = {
            'token': Base64.encode(localStorage.getItem('token')),
            // lang:localStorage.getItem('change-language') || 'zh-CN'
        }
    }
    config.headers.lang = localStorage.getItem('change-language') || 'jp-jp'
    return config
},err=>{
    console.error('请求失败',err)
})

//响应拦截器
instance.interceptors.response.use(res=>{
    //此处对响应数据做处理
    if(res.data.msg === "鉴权错误"){
        localStorage.clear();
        this.$router.push({path:'/Login'})
    }
    return res //该返回对象会传到请求方法的响应对象中
},err=>{
    // 响应错误处理
    return Promise.reject(err);
})

//封装axios请求方法，参数为配置对象
//option = {method,url,params} method为请求方法，url为请求接口，params为请求参数
async function http(option = {}) {
    let result = null
    if(option.method === 'get' || option.method === 'delete'){
        //处理get、delete请求
        await instance[option.method](
            api[option.url],
            {params: option.data}
        ).then(res=>{
            result = res.data
            console.log('API响应原始数据:', result);
            try {
                // 检查result.data是否为字符串，只有字符串才需要解码
                if (typeof result.data === 'string') {
                    try {
                        result.data = JSON.parse(Base64.decode(result.data));
                        console.log('API响应解码后数据:', result.data);
                    } catch (decodeError) {
                        console.error('Base64解码失败，尝试直接解析JSON:', decodeError);
                        try {
                            result.data = JSON.parse(result.data);
                            console.log('直接JSON解析数据:', result.data);
                        } catch (jsonError) {
                            console.error('JSON解析也失败，保持原始数据:', jsonError);
                        }
                    }
                }
            } catch (error) {
                console.error('数据处理失败:', error);
            }
        }).catch(err=>{
            console.error('API请求失败:', err);
            result = {
                code: 500,
                msg: err.message || '网络请求失败',
                data: null
            }
        })
    }else if(option.method === 'post' || option.method === 'put'){
        //处理post、put请求
        await instance[option.method](
                api[option.url],
            option.data
        ).then(res=>{
            result = res.data;
            console.log('API响应原始数据:', result);
            try {
                // 检查result.data是否为字符串，只有字符串才需要解码
                if (typeof result.data === 'string') {
                    try {
                        result.data = JSON.parse(Base64.decode(result.data));
                        console.log('API响应解码后数据:', result.data);
                    } catch (decodeError) {
                        console.error('Base64解码失败，尝试直接解析JSON:', decodeError);
                        try {
                            result.data = JSON.parse(result.data);
                            console.log('直接JSON解析数据:', result.data);
                        } catch (jsonError) {
                            console.error('JSON解析也失败，保持原始数据:', jsonError);
                        }
                    }
                }
            } catch (error) {
                console.error('数据处理失败:', error);
            }
        }).catch(err=>{
            console.error('API请求失败:', err);
            result = {
                code: 500,
                msg: err.message || '网络请求失败',
                data: null
            }
        })
    }
    return result
}

export default http

