<template>
	<div class="index">
		<van-nav-bar :title="baseInfo.name" left-text="" @click-left="toService" left-arrow fixed>
		  <!-- <template #title>
		    <div class="van-image" style="width: 90px;">
           {{ $t('common.homenav') }}
			  </div>
		  </template> -->
		  <template #left>
		    <van-icon name="chat-o" size="26" />
		  </template>
		</van-nav-bar>

		<div class="banner banners" style="margin-top: 5px;height: 10rem;" >
			<swiper v-if="banners.length>0" class="swiper" :options="bannerSwiperOption">
			  <swiper-slide v-for="(v,key) in banners" :key="key">
					<a>
						<img class="banner_img"  :src="v.url" />
					</a>			      
			  </swiper-slide>
			</swiper>
      <div class="network swiper" v-else>
        <van-loading color="#f8f8f8" type="spinner" size="20" />
      </div>
			
		</div>
		<div class="item">
			<div class="title">
				<span class="animate__animated animate__fadeIn">
					HOT
					<i style="font-size: 14px;">
						{{$t('home.gonggao')}}
					</i>
				</span>
			</div>
			<div class="girl">
				<div>
					<!-- <ul class="gaoduan">
						<li v-for="(v,key) in osList" :key="key" @click="profile(v.id)" >
							<img :src="v.img_url || v.cover || v.image" alt="">
							<p>
								{{v.xuanfei_name || '하율'}}
							</p>
							<span>
								<i>
									{{$t('home.qgkf')}}
								</i>
							</span>
						</li>
					</ul> -->
					<ul class="gaoduan" v-if="jsList.length>0">
						<li v-for="(v,key) in jsList" :key="key" @click="profile(v.id)">
							<img :src="v.img_url || v.cover || v.image" alt="">
							<p>
								{{v.xuanfei_name || '민설'}}
							</p>
							<span v-if="v.xuanfei_name">
								<i>
									{{$t('home.qgkf')}}
								</i>
							</span>
						</li>
						
					</ul>
          <ul class="gaoduan" v-else>
            <li class="network gaoduan" v-for="item in 6" :key="item">
              <van-loading color="#f8f8f8" type="spinner" size="20" />
            </li>
          </ul>
				</div>
				<a @click="$router.push({path:'/Choose'});" >
					{{$t('common.more')}}
				</a>
			</div>
		</div>
		<div class="item">
			<div class="title">
				<span class="animate__animated animate__fadeIn">
					{{$t('home.hotgame')}}
				</span>
			</div>
			<ul class="list" v-if="gameitem.length>0">
				<li  @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
					<div class="image">
						<img class="animate__animated animate__flipInX" alt=""  :src="v.ico"></div>
					<p>
						{{v.name}}
					</p>
				</li>
				
			</ul>
      <ul class="list" v-else>
        <div class="network netGame" v-for="item in 4" :key="item">
          <van-loading color="#f8f8f8" type="spinner" size="20" />
        </div>
      </ul>
		</div>
		<div class="item tjlb">
			<div class="title" style="padding-bottom: 10px;">
				<span class="animate__animated animate__fadeIn">
					{{$t('home.hotlist')}}
				</span>
			</div>
      <van-skeleton style="margin-bottom: 30px;" v-for="item in 4" :key="item" avatar-shape="square" title avatar :row="4" :loading="movielist_1.length == 0"></van-skeleton>
      <div class="tj" v-if="userInfo.is_bet == 1">
          <ul>
            <li v-for="(v,key) in movielist_1" :key="key"  >
              <div class="left">
                <div style="display: flex; flex-direction: column;">
                  <img :src="v.img_url || v.cover || v.image" alt="">
                  <p style="margin-top: 6px;">
                    {{v.class_name || ''}}
                  </p>
                </div>
                <span>
                  <i>
                    {{$t('home.gaoji')}}
                  </i>
                </span>
              </div>
              <div class="right">
                <div class="title">
                  <span>
                    <span class="van-badge__wrapper van-icon" style="font-size: 13px;">
                      <img class="van-icon__image" src="/img/vipu.png"></span>
                    {{$t('home.smrz')}}
                  </span>
                  <span>
                    <span class="van-badge__wrapper van-icon" style="font-size: 13px;">
                      <img class="van-icon__image" src="/img/vipu.png"></span>
                    {{$t('home.videorz')}}
                  </span>
                </div>
                <div class="tips">
                  <template v-if="v.tags_arr && v.tags_arr.length > 0">
                    <span v-for="(val,index) in v.tags_arr" :key="index">
                      {{val}}
                    </span>
                  </template>
                  <!-- 如果没有tags_arr，显示编号 -->
                  <!-- <span v-if="!v.tags_arr && v.sn">
                    {{v.sn}}
                  </span> -->
                </div>
                <div class="sm">
                  <p class="sf">
                    {{$t('home.star')}}：
                    <template v-if="typeof v.star === 'string'">
                      <span v-for="(numa,indexs) in parseInt(v.star)" :key="'str-'+indexs"></span>
                    </template>
                    <template v-else-if="Array.isArray(v.star)">
                      <span v-for="(numa,indexs) in v.star" :key="'arr-'+indexs"></span>
                    </template>
                    <span v-else>{{v.star || 0}}</span>
                  </p>
                  <div>
                    {{$t('home.sg')}}: {{v.sg || '0cm'}} {{$t('home.xw')}}：{{v.xw || v.sw || '0'}}
                    {{$t('home.diqu')}}：{{v.class_name || ''}}
                  </div>
                  <!-- 显示人气值 -->
                  <div v-if="v.renqi !== undefined" style="color: #ff6b81; margin-top: 5px;">
                    <i class="van-icon van-icon-like" style="vertical-align: middle;"></i>
                    人气: {{v.renqi}}
                  </div>
                </div>
                <div class="btn"  @click="profile(v.id)">
                  {{$t('home.apply')}}
                </div>
              </div>
            </li>
          </ul>
        </div>
		</div>
		<div class="gundong" data-v-2c0cea1a="" v-if="vip_list.length>0">
			<p data-v-2c0cea1a="">
				{{$t('home.ssxz')}} 
			</p>
			<swiper class="swipers" :options="vipSwiperOption">
			  <swiper-slide v-for="(v,key) in vip_list" :key="key" >
					{{v}} {{$t('home.joinvip')}} 
			  </swiper-slide>
			</swiper>
			
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      notice: "获取中......",
      banners: [],
      basicData:[],
      gameitem: [],
      xuanfeilist_hot: [],
      movielist_0: [],
      movielist_1: [],
      osList: [],
      jsList: [],
      vip_list: [],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        loop: true,
        speed:1000,
        autoplay:true
      },
	  vipSwiperOption: {
		direction: 'vertical',
		slidesPerView:7,
	    loop: true,
	    speed:1000,
	    autoplay:true
	  }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      baseInfo: state => state.baseInfo,
    })
  },
  methods:{
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Certification?key='+key+"&id="+id})
      }

    },
	profile(id) {
		this.$router.push({ path: '/profile?id=' + id  });
	},
	toService(){
    if(this.userInfo.is_bet == 0) return;
	  if(this.$store.getters.getBaseInfo.iskefu == 1){
	    this.$router.push("ServiceOnline");
	  }else {
	    this.$toast(this.$t('common.refresh'));
	  }
	},
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('common.refresh'));
      }, 500);
    },
    getVipList(){
      const japaneseMaleFirstParts = ["Haruto", "Takumi", "Riku", "Daiki", "Kaito", "Souta", "Ren", "Ryota", "Tsubasa", "Itsuki"];
      const japaneseMaleSecondParts = ["Taro", "Kun", "San", "Sensei", "Sama", "Oni", "Senpai", "Ryu", "Kaze", "Shin"];

      // 用于生成随机数字
      function getRandomNumber(limit = 100) {
        return Math.floor(Math.random() * limit); // 生成 0 ~ 99 的随机数字
      }

      // 随机生成韩国男性风格的用户名
      function generateKoreanMaleUsername() {
        // 随机从两个部分中选取姓名前后部分
        const part1 = japaneseMaleFirstParts[Math.floor(Math.random() * japaneseMaleFirstParts.length)];
        const part2 = japaneseMaleSecondParts[Math.floor(Math.random() * japaneseMaleSecondParts.length)];
        // 添加一个随机数字
        const randomKoreanMaleUsername = `${part1}${part2}${getRandomNumber(100)}`;
        return randomKoreanMaleUsername;
      }
      const koreanMaleUsernames = [];
      for (let i = 0; i < 100; i++) {
        koreanMaleUsernames.push(generateKoreanMaleUsername());
      }
      this.vip_list = koreanMaleUsernames;
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        console.log('系统配置数据:', res);
        this.basicData = res.data;
		
        // this.vip_list = this.basicData?.vip_list || [];
        this.getVipList();
        this.getNotice(this.basicData); // 获取公告
        this.getBanner(this.basicData); // 获取banner
        this.getGameItem(); // 获取首页游戏列表
        
        // 直接使用API返回的osList和jsList
        if (this.basicData.osList && Array.isArray(this.basicData.osList)) {
          this.osList = this.basicData.osList;
          console.log('人气值大于1000的选妃列表 (osList):', this.osList);
        } else {
          console.warn('未找到osList数据或格式不正确，使用默认数据');
          // 使用默认数据
          this.osList = [
            {
              id: 1,
              xuanfei_name: '하율',
              img_url: '/img/logo.png',
              is_hot: 1,
              renqi: 1200
            },
            {
              id: 2,
              xuanfei_name: '민설',
              img_url: '/img/logo.png',
              is_hot: 1,
              renqi: 1100
            }
          ];
        }
        
        if (this.basicData.jsList && Array.isArray(this.basicData.jsList)) {
          this.jsList = this.basicData.jsList;
          console.log('人气值在500-1000之间的选妃列表 (jsList):', this.jsList);
        } else {
          console.warn('未找到jsList数据或格式不正确，使用默认数据');
          // 使用默认数据
          this.jsList = [
            {
              id: 3,
              xuanfei_name: '소희',
              img_url: '/img/logo.png',
              is_hot: 0,
              renqi: 900
            },
            {
              id: 4,
              xuanfei_name: '지민',
              img_url: '/img/logo.png',
              is_hot: 0,
              renqi: 800
            }
          ];
        }
        
        // 处理xuanfeilist_hot数据，用于其他功能
        if (this.basicData.xuanfeilist_hot && Array.isArray(this.basicData.xuanfeilist_hot)) {
          this.xuanfeilist_hot = this.basicData.xuanfeilist_hot;
        } else {
          // 如果没有xuanfeilist_hot，则合并osList和jsList作为替代
          this.xuanfeilist_hot = [...this.osList, ...this.jsList];
          console.warn('未找到xuanfeilist_hot数据，使用osList和jsList合并数据代替');
        }
        
        // 获取电影列表数据
        this.getMovieList_0(this.basicData); // 获取首页视频0
        this.getMovieList_1(this.basicData); // 获取首页视频1
        
        console.log('===== 人气列表数据详情 =====');
        console.log('左侧列表 (osList):', this.osList);
        console.log('右侧列表 (jsList):', this.jsList);
        console.log('===== 人气列表数据详情结束 =====');
      }).catch(error => {
        console.error('获取系统配置失败:', error);
        this.$toast(this.$t('more.fy44'));
        
        // 出错时也设置默认数据
        this.osList = [
          {
            id: 1,
            xuanfei_name: '하율',
            img_url: '/img/logo.png',
            is_hot: 1,
            renqi: 1200
          },
          {
            id: 2,
            xuanfei_name: '민설',
            img_url: '/img/logo.png',
            is_hot: 1,
            renqi: 1100
          }
        ];
        
        this.jsList = [
          {
            id: 3,
            xuanfei_name: '소희',
            img_url: '/img/logo.png',
            is_hot: 0,
            renqi: 900
          },
          {
            id: 4,
            xuanfei_name: '지민',
            img_url: '/img/logo.png',
            is_hot: 0,
            renqi: 800
          }
        ];
        
        // 合并osList和jsList作为xuanfeilist_hot
        this.xuanfeilist_hot = [...this.osList, ...this.jsList];
      })
    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
		  console.log('lottery_hot',res.data);
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      if (data && data.movielist_0) {
        this.movielist_0 = data.movielist_0;
      } else if (this.xuanfeilist_hot && this.xuanfeilist_hot.length > 0) {
        // 筛选is_hot为1的项目
        this.movielist_0 = this.xuanfeilist_hot.filter(item => item.is_hot === 1).slice(0, 4);
      }
      console.log('movielist_0数据(JSON):', JSON.stringify(this.movielist_0));
      console.log('movielist_0原始数据:', this.movielist_0);
    },
    getMovieList_1(data){
      if (data && data.movielist_1) {
        this.movielist_1 = data.movielist_1;
      } else if (this.xuanfeilist_hot && this.xuanfeilist_hot.length > 0) {
        // 使用所有xuanfeilist_hot数据
        this.movielist_1 = this.xuanfeilist_hot;
      }
      console.log('movielist_1数据(JSON):', JSON.stringify(this.movielist_1));
      console.log('movielist_1原始数据:', this.movielist_1);
      
      setTimeout(() => {
        console.log('===== 数据加载完成后的检查 =====');
        console.log('osList数据:', JSON.stringify(this.osList));
        console.log('jsList数据:', JSON.stringify(this.jsList));
        console.log('osList数量:', this.osList.length);
        console.log('jsList数量:', this.jsList.length);
        console.log('页面是否有数据:', 
          (this.osList.length > 0 || this.jsList.length > 0 || this.movielist_1.length > 0) 
          ? '有数据' : '无数据');
        
        const hotItems = document.querySelectorAll('.gaoduan li');
        const hotListItems = document.querySelectorAll('.tj li');
        console.log('HOT区域DOM元素数量:', hotItems.length);
        console.log('热门列表DOM元素数量:', hotListItems.length);
        console.log('===== 检查结束 =====');
      }, 500);
    },
    getBanner(data){
      console.log('Banner数据:', data.banners);
      this.banners = data.banners;
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.$store.commit('setUserInfoValue', res.data);
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t('more.fy43'));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }else if(this.userInfo.is_bet == 1){
            this.getBasicConfig();
          }else{
            this.$toast(this.$t('more.fy45'));
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
          this.getBasicConfig();
        }
      })
    },
  },
  mounted () {
    
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    }else{
      this.getBasicConfig();
    }
  }
}

</script>

<style lang='less' scoped>
@import "../../assets/css/basenew.css";
.van-nav-bar {
    z-index: 999;
    position: absolute;
    background: url(/img/header.svg) no-repeat 50%/100%;
}
.network{
  background-color: #e6dedb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.netGame{
  width: 120px;
  height: 120px;
}
::v-deep .tjlb{
  .van-skeleton__avatar{
    width: 110px;
    height: 160px !important;
  }
}
</style>
