export default {
	Language: 'English',
	system: {
		homePage: {
			title1: 'title1',
			title2: 'title2',
			description1: 'Last 7 days',
			description2: 'Nearly six months',
		}
	},
	login:{
		register:'Register',
		usernamePls:'Please enter your username',
		passwordPls:'Please enter password',
		toRegister:'Dont you have an account? Register as a member now',
		doLogin:'Login',	  
	},
	register:{
		repasswordPls:'Please enter the withdrawal password',  
		codePls:'Please enter the invitation code',  
		register:'Register',
		xieyi:'Agree to the Terms of Use',
		xieyiPls:'Please select the account opening agreement below!',
	},
	mine:{
		xyfs:'Credit score',
		ljjf:'Accumulated points',
		recharge:'Recharge',
		withdraw:'Withdraw',
		money:'Having a point',
		infomation:'essential information',
		moneylog:'Points History',
		withdrawrecord:'Withdrawal details',
		gamerecord:'Gift Record',
		notice:'Announcement Matters',
		mymove:'Watch history',
		loginpwd:'Login password',
		paypwd:'Use password',
		loginout:'cancellation',
		paypwdhasseted:'cancellation',
		setbank:'cancellation',
		lorr:'Login/Register',
	},
	infomation:{
		username:'Member ID',
		name:'full name',
		bankcard:'Withdrawal account',
		phone:'phone number',
		recharge:'Recharge',
		amount:'Having a point',
		toserve:'Please contact customer service',
		kfms:'If you want offline charging, please consult customer service. The recharge amount of some settlement channels will be randomly recharged to one decimal place. Please be sure to confirm during settlement',
	},
	withdraw:{
		title:'Withdrawal',
		log:'Withdrawal Record',
		method:'Withdrawal Method',
		avmoney:'Withdrawable balance',
		limit:'Withdrawal limit',
		submit:'Withdrawal',
		xiane:'Single transaction limit',
		numbers:'Withdrawal frequency',
		time:'arrival time',
		zuidi:'Least',
		zuigao:'Highest',
		numsmax:'Maximum daily cash withdrawals',
		ci:'times',
		timedetail:'The general remittance time is about 5 minutes, and the fastest remittance can be made within 2 minutes.',
		bank:'Bank Card',
		status1:'Pending Review',
		status2:'Success',
		status3:'Failed',
	},
	moneylog:{
		title:'Fund Details',
		type1:'Change',
		type2:'Bonus',
		type3:'Task',
		type4:'Bonus',
		type5:'Withdrawal',
		type6:'Withdrawal failed',
	},
	setting:{
		loginpwdtitle:'Change login password',
		oldpwd:'Please enter old password',
		newpwd:'Please enter a new password',
		renewpwd:'Please re-enter the new password',
		change:'change',
		plseall:'Please fill out the complete form!',
		pswwrong:'Password input does not match twice',
		paypwdtitle:'Set fund password',
		paypwd:'Please enter the fund password.',
		repaypwd:'Please re-enter the fund password.',
	},
	home:{
		gonggao:'Enjoy the ultimate luxury experience',
		qgkf:'National',
		hotlist:'Popularity Recommendation List',
		hotgame:'Task Recommendations',
		gaoji:'Advanced',
		smrz:'On site certification',
		videorz:'My own video authentication',
		star:'Rating',
		sg:'Height',
		xw:'Chest circumference',
		diqu:'Serviceable Area',
		apply:'Apply for a date',
		ssxz:'Real time status',
		joinvip:'Joined as a member',
	},
	video:{
		title:'Image Gallery',
		nums:'views',
		bofangliang:'views',
		hotlist:'Popular Recommendations',
		plsrefisrt:'Please recharge and watch the video',
	},
	choose:{
		title:'The best night',
		nav:'Girls',
		apply:'Match application',
		jianjie:'Girls profile',
		move:'Is it movable',
		click:'Click on authentication',
		detail:'detailed information',
		videodetail:'real-time video ',
	},
	common:{
		refresh:'Refresh successful',
		noallow:'Function disabled!',
		disable:'Disable',
		all:'All',
		nomore:'Not anymore.',
		nodata:'No data available',
		submit:'Submit',
		more:'View more data',
		pinglunnav:'Comment',
		pingluntitle:'Comment',
		pldtitle:'Comment Details',
		minenav:'Account',
		homenav:'Home',
		addbank:'Add Bank Card',
		setbank:'Withdrawal Account',
	},
	game:{
		expect:'standard-bearer',
		jieguo:'data',
		da:'Big',
		xiao:'Small',
		dan:'Single',
		shuang:'Double',
		大:'Big',
		小:'Small',
		单:'Single',
		双:'Double',
		order:'Work instruction manual',
		clear:'Clear order',
		submit:'Confirm submission',
		kjcc:'Successful winning, hobby:',
		lxgly:'Please contact your administrator.',
		plsmoney:'Please fill in the amount!',
		plsnum:'Please select a number!',
		moneynote:'Insufficient balance, please contact customer service to recharge!',
		lefttime:'Remaining time',
		lunci:'Round by round',
		switch:'Switch',
		fy1:'Current selection',
		fy2:'Quantity',
		fy3:'Number of votes',
		fy4:'Total amount',
		fy5:'Available points',
		fy6:'Authentication',
		fy7:'Not selected',
	},
	kefu:{
		title:'Online Service',
		lianxi:'Contact',
		content:'We are dedicated to serving you 24/7',
	},
	setname:{
		title:'Change Real Name',
		xingming:'Name',
		tips:'For the security of your account, the actual name must match the name of the bound bank card',
		xmpls:'Please enter the actual name.',
		save:'Save',
		notreset:'Do not repeat the settings!',
		plsentername:'Please enter a name!',
	},
	bank: {
		nameRequired: 'Please set your name first before binding a bank card!',
		payPasswordRequired: 'Please set your withdrawal password first before binding a bank card!',
		bindCardTitle: 'Withdrawal Account Registration',
		selectBankCard: 'Please select a bank card',
		accountNumber: 'Account Number',
		enterAccountNumber: 'Please enter the bank card number',
		accountHolder: 'Account Holder',
		enterRealName: 'Please enter your real name',
		withdrawalPassword: 'Withdrawal Password',
		enterWithdrawalPassword: 'Please enter the withdrawal password',
		addBankCard: 'Add Bank Card',
		enterBankCardNumber: 'Please enter the bank card number!',
		selectBank: 'Please select a bank!',
		bankCard: 'Bank Card',
	},
	metaTitles:{
		title1: 'Romantic',
		title2: 'Romantic',
		title3: 'Romantic-Profile',
		title4: 'Manager Reviews',
		title5: 'Manager Reviews',
		title6: 'Profile',
		title7: 'Profile',
		title8: 'Romantic-My',
		title9: 'Videos',
		title10: 'Games',
		title11: 'Login',
		title12: 'Register',
		title13: 'Online Support',
		title14: 'Online Support',
		title15: 'Settings',
		title16: 'Profile',
		title17: 'Real Name Edit',
		title18: 'Gender Edit',
		title19: 'Payment Password Management',
		title20: 'Login Password Management',
		title21: 'Detail Information Page',
		title22: 'Romantic-Information Announcement',
		title23: 'Video Playback',
		title24: 'Enter Payment Card',
		title25: 'Enter Payment Card',
		title26: 'Cash Withdrawal',
		title27: 'Recharge',
		title28: 'Personal Report',
		title29: 'Cash Withdrawal Record',
		title30: 'Game Record',
		title31: 'Game Details',
		title32: 'Balance Record',
		title33: 'Romantic-My Movies'
	},
	more: {
		fy1: 'Possible',
		fy2: 'Thumb',
		fy3: 'Security Strategy',
		fy4: 'To protect members\' personal information, Romantic only accepts members referred by those who have completed the personal verification service or are already verified members.',
		fy5: 'Operation Records',
		fy6: 'Order Content',
		fy7: 'Every {count} Punctuation',
		fy8: 'All Operations',
		fy9: 'Reset',
		fy10: 'Confirm',
		fy11: 'All',
		fy12: 'Bonus Points',
		fy13: 'Results Awaiting',
		fy14: 'Task Gold Details',
		fy15: 'Task Results',
		fy16: 'Minutes',
		fy17: 'Operation Points',
		fy18: 'Gift Completed.',
		fy19: 'Order Time',
		fy20: 'Details',
		fy21: 'Content',
		fy22: 'Amount',
		fy23: 'Result',
		fy24: 'Time',
		fy25: 'Victory',
		fy26: 'Task Failed',
		fy27: 'System Notification',
		fy28: 'My Movies',
		fy29: 'Complete tasks to earn more movie tickets',
		fy30: 'Film Reel',
		fy31: 'Follow List',
		fy32: 'No Data Available',
		fy33: 'Please enter your withdrawal password.',
		fy34: 'Password',
		fy35: 'Please enter the correct amount.',
		fy36: 'Please select the bank card for withdrawal.',
		fy37: 'Please enter your payment password.',
		fy38: 'Notification',
		fy39: 'Current Phone Number',
		fy40: 'New Phone Number',
		fy41: 'Modify Phone Number',
		fy42: 'Please contact the manager for verification.',
		fy43: 'Account offline',
		fy44:'Failed to obtain system configuration, please check the network connection',
		fy45:'Your current network is abnormal, please change the network',
		fy46:'Change avatar',
	  }	  
};