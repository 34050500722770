<template>
  <div class="mine">
    <div class="top">
      <div class="setting">
        <van-icon name="chat-o" size="1.8rem" color="#fff" @click="toService" />
        <!-- <i class="van-badge__wrapper van-icon van-icon- icon-xiaoxi"  style="font-size: 28px;"></i> -->
      </div>
      <div class="avatar" @click="userInfo.is_bet == 0?'':doLogin()">
        <van-image v-if="userInfo.is_bet == 1" round width="4.2rem" height="4.2rem" :src="userInfo.header_img || baseInfo.ico">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
        <div v-else style="background-color: #e6dedb;width: 65px;height: 65px;display: flex;align-items: center;justify-content: center;border-radius: 50%;">
          <van-loading color="#fff" type="spinner" size="20" />
        </div>
        <div class="me">
          <div class="user" style="flex-direction: initial; align-items: center;">
            <p style="padding-bottom: 0px;">{{ fliterNet(userInfo.username) }}</p>
            <span style="margin-left: 10px;" v-if="userInfo.is_vip == 1">
              <img :src="'img/vip' + userInfo.vip_level + '.png'" alt="" style="width: 90px;">
            </span>
          </div>
          <div class="ps">
            <span style="padding-left: 0px; margin: 4px 0px 2px;">{{ $t('mine.xyfs') }}:{{ fliterNet(userInfo.xyjf) }}</span>
          </div>
          <div class="ps">
            <div class="proess">
              <span style="width: 100%;"></span>
            </div>
            <span>{{ $t('mine.ljjf') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="integral">
        <div @click="userInfo.is_bet == 0?'':doPay()">
          <i class="van-badge__wrapper van-icon" style="color: rgb(0, 0, 0); font-size: 26px;">
            <img class="van-icon__image" src="/img/qianbao.png">
          </i>
          <span>{{ $t('mine.recharge') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':doWithdrawal()">
          <i class="van-badge__wrapper van-icon" style="color: rgb(0, 0, 0); font-size: 26px;">
            <img class="van-icon__image" src="/img/piao.png">
          </i>
          <span>{{ $t('mine.withdraw') }}</span>
        </div>
      </div>
      <div class="mineJf animate__animated animate__flipInX">
        <div class="title">
          <p>{{ $t('mine.money') }}</p>
        </div>
        <div class="reload">
          <h1>{{ fliterNet(userInfo.money) }}</h1>
          <div @click="userInfo.is_bet == 0?'':onRefresh()">
            <span>(JPY)</span>
            <van-icon name="/img/reload.png" size="1.2rem" color="#fff" />
          </div>
        </div>
      </div>
      <div class="list animate__animated animate__fadeIn">
        <div @click="userInfo.is_bet == 0?'':showSetting()"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/zhangdan.png"></i><span>{{ $t('mine.infomation') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':$router.push({ path: '/MoneyLog' });"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/shujuku.png"></i><span>{{ $t('moneylog.title') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':$router.push({ path: '/WithdrawRecord' });"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/zhangdan2.png"></i><span>{{ $t('mine.withdrawrecord') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':$router.push({ path: '/GameRecord' });"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/youxi.png"></i><span>{{ $t('mine.gamerecord') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':toNotice()"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/diwu.png"></i><span>{{ $t('mine.notice') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':$router.push({ path: '/Mymove' });"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/dianying.png"></i><span>{{ $t('mine.mymove') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':toLoginPassword()"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/suo.png"></i><span>{{
                $t('mine.loginpwd') }}</span>
        </div>
        <div @click="userInfo.is_bet == 0?'':toPayPassword()"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/zfmm.png"></i><span>{{ $t('mine.paypwd') }}</span></div>
        <div @click="userInfo.is_bet == 0?'':toSetBank()"><i class="van-badge__wrapper van-icon"
            style="color: rgb(134, 134, 134); font-size: 26px;"><img class="van-icon__image"
              src="/img/anquan.png"></i><span>{{ $t('infomation.bankcard') }}</span>
        </div>
      </div>
      <a class="btns" @click="userInfo.is_bet == 0?'':loginout()">{{ $t('mine.loginout') }}</a>
    </div>
  </div>


</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      baseInfo: state => state.baseInfo,
    })
  },
  methods: {
    fliterNet(val){
      if(this.userInfo.is_bet == 1) return val;
      else return ''
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.$toast(this.$t('common.refresh'));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    exit() {
      this.$toast("请完成任务单后进入");
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toSetBank(){
      this.$router.push({path:'/Setbank'});
    },
    toPayPassword() {
      if (!!this.userInfo.paypassword) {
        this.$toast(this.$t('mine.paypwdhasseted'));
      } else {
        this.$router.push({ path: '/SetPayPassword' });
      }
    },
    toLoginPassword() {
      this.$router.push({ path: '/SetLoginPassword' });
    },
    loginout() {
      localStorage.clear()
      this.$router.push({ path: '/Login' });
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast(this.$t('common.refresh'));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doPay() {
      this.$router.push("recharge");
      return false;
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail(this.$t('mine.setbank'));
        }
      })
    },
    toService() {
      if(this.userInfo.is_bet == 0) return;
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail(this.$t('common.noallow'));
      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.$store.commit('setUserInfoValue', res.data);
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t('more.fy43'));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }else if(this.userInfo.is_bet != 1){
            this.$toast(this.$t('more.fy45'));
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
    } else {
      this.$router.push({path:'/Login'})
      this.userInfo.username = this.$t('mine.lorr');
      this.userInfo.ip = "登录可享受更多服务！";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/basenew.css";

:export {
  gbcolor: linear-gradient(30deg, #7d76ef, #d63f8c)
}

.mine {
  width: 100%;
  min-height: 100%;
  background: #f2f2f5
}

.mine .van-share-sheet__description span {
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  border-radius: 4px;
  color: #fff;
  margin: 4px 0
}

.mine .top {
  width: 100%;
  height: 13.33333rem;
  background: url(/img/usertop.png) no-repeat bottom/160%
}

.mine .top .setting {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding-right: .33333rem
}

.mine .top .setting i {
  margin: .5rem 1rem;
  color: #fff
}

.mine .top .avatar {
  width: 100%;
  padding: 0 6%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1.33333rem
}

.mine .top .avatar .van-image {
  border: 4PX solid #fff
}

.mine .top .avatar .login {
  text-align: left;
  padding-left: 1rem
}

.mine .top .avatar .login> {
  padding: .13333rem 0
}

.mine .top .avatar .login p {
  font-size: 1.13333rem;
  color: #fff
}

.mine .top .avatar .login span {
  font-size: .8rem;
  color: hsla(0, 0%, 100%, .6)
}

.mine .top .avatar .me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1.33333rem;
  flex: 1
}

.mine .top .avatar .me .user {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column
}

.mine .top .avatar .me .user p {
  font-size: 1rem;
  color: #fff;
  padding-bottom: .33333rem
}

.mine .top .avatar .me .user img {
  width: 4.66667rem;
  margin: 0 0
}

.mine .top .avatar .me .user span {
  color: #ffd849;
  font-size: .86667rem;
  font-weight: 600;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center
}

.mine .top .avatar .me .user span>span {
  position: absolute;
  right: .66667rem;
  color: #fff;
  font-size: .8rem
}

.mine .top .avatar .me .ps {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.mine .top .avatar .me .ps>span {
  padding-left: .33333rem;
  color: #ffe0f6;
  font-size: .73333rem
}

.mine .top .avatar .me .proess {
  width: 40%;
  height: .26667rem;
  background: #e5d3cb;
  margin: .46667rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: .66667rem;
  overflow: hidden
}

.mine .top .avatar .me .proess span {
  background: #fff;
  height: 100%;
  width: 50%
}

.mine .top .avatar .me .jf {
  color: #fff;
  font-size: .8rem
}

.mine .center {
  width: 94%;
  margin: -10% auto 0
}

.mine .center .integral {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  align-items: center;
  border-radius: .33333rem;
  box-shadow: 0 .13333rem .2rem rgba(0, 0, 0, .04);
  position: relative;
  z-index: 999
}

.mine .center .integral:before {
  content: "";
  position: absolute;
  width: 3PX;
  height: 1.33333rem;
  border-radius: .66667rem;
  background: #f2f2f5
}

.mine .center .integral>div {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center
}

.mine .center .integral>div span {
  margin-left: .66667rem;
  color: #432989;
  font-weight: 600
}

.mine .center .mineJf {
  width: 100%;
  background: #a06098;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: .86667rem 0;
  margin-top: .66667rem;
  border-radius: .33333rem;
  box-shadow: 0 .13333rem .2rem rgba(0, 0, 0, .04)
}

.mine .center .mineJf .title {
  display: flex;
  padding: 0 0 .66667rem;
  width: 90%;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center
}

.mine .center .mineJf .title p {
  color: #fff;
  font-size: .93333rem;
  font-weight: 600
}

.mine .center .mineJf .reload {
  width: 90%;
  margin: 0 auto;
  display: flex;
  padding-top: .33333rem;
  justify-content: space-between;
  align-items: center
}

.mine .center .mineJf .reload h1 {
  color: #fff;
  font-size: 1.33333rem
}

.mine .center .mineJf .reload>div {
  color: #fff;
  font-size: .86667rem;
  position: absolute;
  top: 17rem;
  margin: auto;
  right: 1.33333rem;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.mine .center .mineJf .reload>div span {
  padding-right: .66667rem
}

.mine .center .mineJf .reload>div i {
  background: #fff;
  color: #9a6cd9;
  border-radius: 50%;
  padding: .33333rem
}

.mine .center .btns {
  display: inline-block;
  width: 90%;
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  padding: .86667rem 0;
  border-radius: .33333rem;
  font-size: .93333rem;
  cursor: pointer;
  margin: 1.66667rem auto 3.33333rem
}

.mine .center .list {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: .86667rem 0;
  align-items: center;
  flex-wrap: wrap;
  border-radius: .33333rem;
  box-shadow: 0 .13333rem .2rem rgba(0, 0, 0, .04);
  margin-top: .8rem
}

.mine .center .list>div {
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 6%
}

.mine .center .list>div span {
  color: #432788;
  padding: .53333rem 0
}

.mine .center .list>div span>i {
  padding-left: .13333rem
}

.mine .center .list>div>i {
  padding: .2rem
}

</style>
